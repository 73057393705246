<template>
  <footer class="goro-footer-container" id="footer">
    <b-container>
      <div class="footer">
        <b-row class="mb-2">
          <b-col cols="12" md="3">
            <router-link class="" to="/">
              <img width="150" height="49" class="goro-logo" src="@/assets/img/logo-goro-white.png" alt="GORO | Property for All" />
            </router-link>
            <ul class="footer__social">
              <li>
                <a :href=linkedinUrl target="_blank">
                  <img width="24" height="24" src="@/assets/img/socials/linkedin.svg" alt="" />
                </a>
              </li>
              <li>
                <a :href=facebookUrl target="_blank">
                  <img width="24" height="24" src="@/assets/img/socials/facebook.svg" alt="" />
                </a>
              </li>
              <li>
                <a :href=instagramUrl target="_blank">
                  <img width="24" height="24" src="@/assets/img/socials/instagram.svg" alt="" />
                </a>
              </li>
              <li>
                <a :href=tiktokUrl target="_blank">
                  <img width="20" height="20" src="@/assets/img/socials/tiktok.svg" alt="" />
                </a>
              </li>
              <li>
                <a :href=youtubeUrl target="_blank">
                  <img width="29" height="20" src="@/assets/img/socials/youtube.svg" alt="GORO | Youtube" />
                </a>
              </li>
            </ul>
          </b-col>
          <b-col cols="12" md="5">
            <ul class="goro-offices">
              <li>
                <a :href="offices.Bali" :title="$t('GORO_OFFICE.GORO_BALI_OFFICE.NAME')" target="_blank">
                  <img class="office" width="14" height="16.8" src="@/assets/img/icons/office.svg" :alt="$t('GORO_OFFICE.GORO_BALI_OFFICE.NAME')" />
                </a>
                <div>
                  <h3 class="office-name">
                    <a :href="offices.Bali" :title="$t('GORO_OFFICE.GORO_BALI_OFFICE.NAME')" target="_blank">
                      {{ $t("GORO_OFFICE.GORO_BALI_OFFICE.NAME") }}
                    </a>
                  </h3>
                  <p class="office-address">
                    {{ $t("GORO_OFFICE.GORO_BALI_OFFICE.ADDRESS") }}
                  </p>
                </div>
              </li>
              <li>
                <a :href="offices.Jakarta" :title="$t('GORO_OFFICE.GORO_JAKARTA_OFFICE.NAME')" target="_blank">
                  <img class="office" width="14" height="16.8" src="@/assets/img/icons/office.svg" :alt="$t('GORO_OFFICE.GORO_JAKARTA_OFFICE.NAME')" />
                </a>
                <div>
                  <h3 class="office-name">
                    <a :href="offices.Jakarta" :title="$t('GORO_OFFICE.GORO_JAKARTA_OFFICE.NAME')" target="_blank">
                      {{ $t("GORO_OFFICE.GORO_JAKARTA_OFFICE.NAME") }}
                    </a>
                  </h3>
                  <p class="office-address">
                    {{ $t("GORO_OFFICE.GORO_JAKARTA_OFFICE.ADDRESS") }}
                  </p>
                </div>
              </li>
            </ul>
          </b-col>
          <b-col cols="12" md="4">
            <h3 class="weight-text sub-heading">
              {{ $t("FOOTER.DOWNLOAD_APPLICATION") }}
            </h3>
            <ul class="goro-on-apps">
              <li class="item">
                <a :href="appStoreUrl" target="_blank" @click="onClickStore(appStoreEvent)">
                  <img class="mb-2" width="175" height="51" src="@/assets/img/store/appstore.png"
                    alt="" />
                </a>
              </li>
              <li class="item">
                <a :href="playStoreUrl" target="_blank" @click="onClickStore(playStoreEvent)">
                  <img class="mb-2" width="175" height="51" src="@/assets/img/store/playstore.png" alt="" />
                </a>
              </li>
            </ul>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col cols="12" md="3">
            <h3 class="weight-text sub-heading">
              {{ $t("common.COMPANY") }}
            </h3>
            <ul class="footer-links">
              <li class="weight-text">
                <router-link :to="{ name: 'aboutUs' }">{{ $t("HEADER.ABOUT_US") }}</router-link>
              </li>
              <li class="weight-text">
                <a :href=careerUrl target="_blank">{{ $t("FOOTER.CAREERS") }}</a>
              </li>
              <li class="weight-text">
                <router-link :to="{ name: 'privacyPolicy' }">{{ $t("FOOTER.PRIVACY_POLICY") }}</router-link>
              </li>
              <li class="weight-text">
                <router-link :to="{ name: 'termsAndConditions' }">{{
                  $t("FOOTER.TERMS_AND_CONDITIONS")
                }}
                </router-link>
              </li>
            </ul>
          </b-col>
          <b-col cols="12" md="2">
            <h3 class="weight-text sub-heading">
              {{ $t("common.PRODUCT") }}
            </h3>
            <ul class="footer-links">
              <li class="weight-text">
                <router-link :to="{ name: 'home' }">{{ $t("HEADER.HOME") }}</router-link>
              </li>
              <li class="weight-text">
                <router-link :to="{ name: 'marketplace' }">{{ $t("HEADER.MARKETPLACE") }}</router-link>
              </li>
            </ul>
          </b-col>
          <b-col cols="12" md="3">
            <h3 class="weight-text sub-heading">
              {{ $t("FOOTER.CONTACT_US") }}
            </h3>
            <ul class="footer-links footer-contact">
              <li>
                <a :href=mailToCustomerSupport target="_blank" class="font-normal pr-4">
                  <img width="20" height="16" src="@/assets/img/icons/email@3x.png" alt="GORO | Customer service email" /> <span>{{ $t("FOOTER.CUSTOMER_SERVICES_EMAIL_SUPPORT") }}</span>
                </a>
              </li>
              <li>
                <a :href=getWaSupportByLang() target="_blank">
                  <img width="20" height="20" src="@/assets/img/icons/wa@3x.png" alt="GORO | Customer service whatsapp" /> <span>{{ $t("FOOTER.CUSTOMER_SERVICES_WHATSAPP_SUPPORT") }}</span>
                </a>
              </li>
            </ul>
          </b-col>
          <b-col cols="12" md="4">
            <h3 class="weight-text sub-heading">
              {{ $t("common.REGISTERED_ON") }}
            </h3>
            <b-row class="align-items-center ml-0">
              <a :href="kominfoUrl" target="_blank" class="external-link kominfo mr-4">
                <img class="mb-2" width="121" height="84" src="@/assets/img/registered-on/logo_kominfo.png"
                     alt="GORO on KOMINFO"/>
              </a>
              <a :href="TSIKANUrl" target="_blank" class="external-link">
                <img class="mb-2" width="203" height="90" src="@/assets/img/registered-on/logo_tsi_kan.png" alt="GORO on TSI KAN"/>
              </a>
            </b-row>
          </b-col>
        </b-row>
        <hr class="solid">
        <div class="font-semibold" style="font-size: 15px">{{ $t("SANDBOX.HEADER_FOOTER") }}</div>
        <div class="pt-4">{{ $t("FOOTER.TOKEN_PURCHASE_NOTE") }}</div>
        <div class="pt-3 pb-3">{{ $t("FOOTER.COPYRIGHT") }}</div>
        <div>{{ $t("FOOTER.DISCLAIMER") }}</div>
        <b-row align-h="between">
          <b-col cols="12" lg="12" xl="12">
            <div class="font-bold pt-4">{{ $t("FOOTER.CONSUMER_COMPLAINT_DIRECTORATE_GENERAL") }}</div>
            <div class="pt-2 pb-2">{{ $t("FOOTER.CONSUMER_COMPLAINT_MINISTRY_OF_TRADE") }}</div>
            <a :href=whatsappComplainService target="_blank">
              <span>{{ $t("FOOTER.CONSUMER_COMPLAINT_CONTACT") }}</span>
            </a>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </footer>
</template>
<script>
import externalSites from "@/constants/externalSites"
import { gtmTrackEvent } from "../../helpers/gtm"
import { GTM_EVENT_NAMES } from "../../constants/gtm"

export default {
  data() {
    return {
      facebookUrl: externalSites.SOCIAL_MEDIA.FACEBOOK,
      linkedinUrl: externalSites.SOCIAL_MEDIA.LINKEDIN,
      instagramUrl: externalSites.SOCIAL_MEDIA.INSTAGRAM,
      tiktokUrl: externalSites.SOCIAL_MEDIA.TIKTOK,
      sellMyProperty: externalSites.MAIL_TO.SELL_MY_PROPERTY,
      careerUrl: externalSites.SOCIAL_MEDIA.CAREERS,
      mailToContact: externalSites.MAIL_TO.CONTACT_US,
      mailToCustomerSupport: externalSites.MAIL_TO.CUSTOMER_SUPPORT,
      whatsappSupports: externalSites.WHATSAPP_SUPPORTS,
      whatsappComplainService: externalSites.WHATSAPP.COMPLAIN_SERVICE,
      playStoreUrl: externalSites.STORE.GOOGLE_PLAY,
      appStoreUrl: externalSites.STORE.APP_STORE,
      appStoreEvent: GTM_EVENT_NAMES.APP_STORE_BUTTON,
      playStoreEvent: GTM_EVENT_NAMES.PLAY_STORE_BUTTON,
      youtubeUrl: externalSites.SOCIAL_MEDIA.YOUTUBE,
      kominfoUrl: externalSites.REGISTERED_ON.KOMINFO,
      TSIKANUrl: externalSites.REGISTERED_ON.TSI_KAN,
      offices: {
        Bali: externalSites.OFFICES.GORO_BALI_OFFICE,
        Jakarta: externalSites.OFFICES.GORO_JAKARTA_OFFICE
      },
    }
  },
  methods: {
    onClickStore(name){
      gtmTrackEvent({
        event: name,
      })
    },
    getWaSupportByLang() {
      if (this.whatsappSupports[this.$i18n.locale]) {
        return this.whatsappSupports[this.$i18n.locale]
      }
      return this.whatsappSupports.id
    },
  },
}
</script>
<style lang="scss">
.hide-header-footer #footer {
  display: none;
}

.footer ul li {
  list-style: none;
}

.footer ul li a {
  font-size: 16px;
  color: #DEE2E6;

  &:hover {
    color: #F8F9FA;
  }
}

.footer div {
  font-size: 12px;
  white-space: pre-wrap;
  color: #DEE2E6;
}

.footer a span {
  font-size: 12px;
  color: #F8F9FA;
}

ul.footer__social {
  padding-left: 0;
  margin-top: 15px;
  margin-bottom: 15px;
}

ul.footer__social li {
  margin-right: 10px;
  display: inline-block;
  padding-bottom: 10px;
}

hr.solid {
  opacity: 0.7;
  border-top: 1px solid #F8F9FA;
  margin-top: 5px;
  margin-bottom: 20px;
}

.footer__copyright {
  padding-top: 10px;
  padding-bottom: 15px;
}

.goro-footer-container{
  background-color: var(--primary-color);
  padding-bottom: 32px !important;
  padding-top: 32px;
  --pdx-min-height: 0px;
  --wr-max: 1030px;
  .goro-logo{
    margin-left: -10px;
    margin-bottom: 15px;
  }
  .external-link{
    &:hover{
      img {
        opacity: .8;
      }
    }
    &.kominfo{
      img{
        margin-left: -5px;
      }
    }
  }
  .goro-offices{
    margin: 0;
    padding: 0;
    li{
      padding-bottom: 10px;
      display: flex;
      align-items: flex-start;
      img.office{
        margin-top: 3px;
        margin-right: 9px;
      }
    }
    .office-name{
      font-size: 16px;
      font-weight: 700;
      color: var(--primary-light-color);
      margin-top: 0px !important;
      margin-bottom: 5px !important;
      a{
        font-size: 16px;
        font-weight: 700;
        color: var(--primary-light-color);
        margin-top: 0px !important;
        margin-bottom: 5px !important;
        &:hover{
          color: var(--footer-text);
        }
      }
    }
    .office-address{
      color: var(--primary-light-color);
      font-size: 12px;
      a{
        color: var(--primary-light-color);
        font-size: 12px;
        &:hover{
          color: var(--footer-text);
        }
      }
    }
  }
  .goro-on-apps{
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    .item{
      flex: 1;
      img{
        width: 100%;
        height: auto;
      }
    }
  }
  h3.sub-heading{
    font-size: 16px;
    font-weight: 700;
    color: var(--primary-light-color);
  }
  .footer-links{
    margin: 0;
    padding: 0;
    li{
      padding-bottom: 7px;
      a{
        font-size: 16px;
        color: var(--footer-text);
        &:hover {
          color: #F8F9FA;
        }
      }
    }
    &.footer-contact{
      li {
        a{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          img{
            margin-right: 7px;
          }
          span{
            font-size: 16px;
            color: var(--footer-text);
          }
          &:hover {
            span{
              color: #F8F9FA;
            }
          }
        }
      }
    }
  }
}
</style>
