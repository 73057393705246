<template>
  <b-modal v-model="showModal" id="full-screen-modal" size="huge" header-bg-variant="dark" header-text-variant="light"
            header-class="modal-header" body-class="modal-body-full" :propertyId="propertyId" @hidden="onHidden()" hide-footer>
    <template #modal-header>
      <h5 class="modal-header-title">{{ title }}</h5>
      <button type="button" class="close font-weight-bold" aria-label="Close" @click="onClose({ show: false })">×</button>
    </template>
    <div class="container-fluid pl-0 pr-0">
      <b-tabs pills card lazy class="cls-goro-custom-tabpanel" nav-wrapper-class="container">
        <b-tab v-if="canUpdatePropertyDetail" title="Property Details" active class="pl-0 pr-0">
          <PropertyDetail :propertyId="updateDetailPropertyId" :isEditing="isEditing" @onClose="onClose" @onCancel="onCancel"></PropertyDetail>
        </b-tab>
        <b-tab v-if="isEditing && canViewFinancials" title="Financials" class="pl-0 pr-0">
          <Financial :propertyId="updateDetailPropertyId" :isEditing="isEditing" @onCancel="onCancel"></Financial>
        </b-tab>
        <b-tab v-if="isEditing && canViewMilestone" title="Milestone" class="pl-0 pr-0">
          <Milestone :propertyId="updateDetailPropertyId" :isEditing="isEditing" @onCancel="onCancel"></Milestone>
        </b-tab>
        <b-tab v-if="isEditing && canViewImagesVisibility" title="Image Visibility" class="pl-0 pr-0">
          <ImageVisibility :propertyId="updateDetailPropertyId" :isEditing="isEditing" @onCancel="onCancel"></ImageVisibility>
        </b-tab>
        <b-tab v-if="isEditing && canViewPropertyDetail" title="Update Status and Token" class="pl-0 pr-0">
          <PropertyStatus :propertyId="updateDetailPropertyId" :isEditing="isEditing" @onCancel="onCancel"></PropertyStatus>
        </b-tab>
      </b-tabs>
    </div>
  </b-modal>
</template>

<script>
  import Editor from "@anhoang/tinymce-vue"
  import { userHasPermission } from "../../../helpers/common"
  import LocationPicker from "../../../components/Maps/LocationPicker.vue"
  import LinkInput from '../../../components/LinkInput.vue'
  import { USER_PERMISSIONS } from "../../../constants/permissions"
  import PropertyDetail from "@/pages/admin/forms/properties/PropertyDetail.vue"
  import Financial from "@/pages/admin/forms/properties/Financial.vue"
  import Milestone from "@/pages/admin/forms/properties/Milestone.vue"
  import ImageVisibility from "@/pages/admin/forms/properties/ImageVisibility.vue"
  import PropertyStatus from "@/pages/admin/forms/properties/PropertyStatus.vue"

  export default {
    components: {
      LocationPicker,
      Editor,
      LinkInput,
      PropertyDetail,
      Financial,
      Milestone,
      ImageVisibility,
      PropertyStatus,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      theme: {
        type: String,
        default: "light"
      },
      title: {
        type: String,
        default: ""
      },
      propertyId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        showModal: false,
        updatePropertyId: null,
        isEditing: true,
      }
    },
    watch: {
      show(value) {
        this.showModal = value
      },
      async propertyId(v) {
        this.updatePropertyId = v
      },
    },
    async mounted() {
    },
    methods: {
      onHidden: function () {
        this.$emit("onClose", {
          show: false,
          reload: true,
        })
      },
      onClose: function (v) {
        this.showModal = false
      },
      onCancel() {
        this.showModal = false
      },
    },
    computed: {
      updateDetailPropertyId() {
        return this.updatePropertyId
      },

      canUpdatePropertyTokenAndStatus() {
        return userHasPermission(USER_PERMISSIONS.PROPERTY.APPROVE_PROPERTY_STATUS_AND_TOKEN)
      },

      canViewImagesVisibility() {
        return userHasPermission(USER_PERMISSIONS.PROPERTY_IMAGE.VIEW_PROPERTY_IMAGE)
      },

      canViewMilestone() {
        return userHasPermission(USER_PERMISSIONS.PROPERTY.VIEW_PROPERTY_MILESTONE)
      },

      canViewFinancials() {
        return userHasPermission(USER_PERMISSIONS.PROPERTY_FINANCIAL.VIEW_ALL_PROPERTY_FINANCIALS)
      },

      canUpdatePropertyDetail() {
        return userHasPermission(USER_PERMISSIONS.PROPERTY.UPDATE_PROPERY_DETAIL)
      },

      canViewPropertyDetail() {
        return userHasPermission(USER_PERMISSIONS.PROPERTY.VIEW_PROPERTY)
      },
    },
  }
  </script>
  <style lang="scss" scoped>
  .modal-header-title {
    margin: 0;
    font-weight: bold;
  }

  .close {
    color: #ffffff;
    opacity: .8;
    padding-bottom: 20px !important;
    /*!* opacity: .5; */
  }
  
  </style>
