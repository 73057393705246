<template>
  <b-modal v-model="showModal" id="full-screen-modal" size="huge" header-bg-variant="dark" header-text-variant="light"
            header-class="modal-header" footer-class="modal-footer" body-class="modal-body-full" :propertyId="propertyId" @hidden="onHidden()" hide-footer>
    <template #modal-header>
      <h5 class="modal-header-title">{{ titleModal }}</h5>
      <button type="button" class="close font-weight-bold" aria-label="Close" @click="onClose({ show: false })">×</button>
    </template>
    <div class="container-fluid pl-0 pr-0">
      <b-tabs pills card lazy class="cls-goro-custom-tabpanel" nav-wrapper-class="container">
        <b-tab v-if="canViewPropertyDetail || canUpdatePropertyDetail" title="Property Details" active class="pl-0 pr-0">
          <PropertyDetail :propertyId="viewPropertyId" :isEditing="isEditing" @onClose="onClose" @onCancel="onCancel"></PropertyDetail>
        </b-tab>
        <b-tab v-if="canViewFinancials" title="Financials" class="pl-0 pr-0">
          <Financial :propertyId="viewPropertyId" :isEditing="isEditing" @onCancel="onCancel"></Financial>
        </b-tab>
        <b-tab v-if="canViewMilestone" title="Milestone" class="pl-0 pr-0">
          <Milestone :propertyId="viewPropertyId" :isEditing="isEditing" @onCancel="onCancel"></Milestone>
        </b-tab>
        <b-tab v-if="canViewImagesVisibility" title="Image Visibility" class="pl-0 pr-0">
          <ImageVisibility :propertyId="viewPropertyId" :isEditing="isEditing" @onCancel="onCancel"></ImageVisibility>
        </b-tab>
        <b-tab v-if="canViewPropertyDetail" title="Update Status and Token" class="pl-0 pr-0">
          <PropertyStatus :propertyId="viewPropertyId" :isEditing="isEditing" @onCancel="onCancel"></PropertyStatus>
        </b-tab>
      </b-tabs>
    </div>
  </b-modal>
</template>

<script>
  import Editor from "@anhoang/tinymce-vue"
  import propertiesService from "../../../services/properties.service"
  import { userHasPermission } from "../../../helpers/common"
  import LocationPicker from "../../../components/Maps/LocationPicker.vue"
  import LinkInput from '../../../components/LinkInput.vue'
  import { PROPERTY_APPROVAL_STATUS } from "../../../constants/constants"
  import { USER_PERMISSIONS } from "../../../constants/permissions"
  import PropertyDetail from "@/pages/admin/forms/properties/PropertyDetail.vue"
  import Financial from "@/pages/admin/forms/properties/Financial.vue"
  import Milestone from "@/pages/admin/forms/properties/Milestone.vue"
  import ImageVisibility from "@/pages/admin/forms/properties/ImageVisibility.vue"
  import PropertyStatus from "@/pages/admin/forms/properties/PropertyStatus.vue"

  export default {
    components: {
      LocationPicker,
      Editor,
      LinkInput,
      PropertyDetail,
      Financial,
      Milestone,
      ImageVisibility,
      PropertyStatus,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      theme: {
        type: String,
        default: "light"
      },
      title: {
        type: String,
        default: ""
      },
      propertyId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        showModal: false,
        viewPropertyId: null,
        property: null,
        isEditing: false,
      }
    },
    
    watch: {
      "map.location"(v) {
        this.property.lat = v[1]
        this.property.lng = v[0]
      },
      async propertyId(v) {
        if (v) {
          this.viewPropertyId = v
          this.property = await propertiesService.getById(this.propertyId)
        }
      },
      show(value) {
        this.showModal = value
      },
    },
    async mounted() {
      if (this.propertyId) {
        this.property = await propertiesService.getById(this.propertyId)
      }
    },
    methods: {
      onClose: function (v) {
        this.showModal = false
      },
      onHidden: function (v) {
        this.$emit("onClose", {
          show: false,
          reload: true,
        })
      },
      onCancel() {
        this.showModal = false
      },
    },
    computed: {
      titleModal() {
        if (this.canApprovePropertyPublish && this.property && this.property.approval_status === PROPERTY_APPROVAL_STATUS.PENDING) {
          return 'Approve Property'
        } 
        return this.title
      },
      canApprovePropertyPublish() {
        return userHasPermission(USER_PERMISSIONS.PROPERTY.APPROVE_PROPERTY_PUBLISH)
      },

      canUpdatePropertyTokenAndStatus() {
        return userHasPermission(USER_PERMISSIONS.PROPERTY.APPROVE_PROPERTY_STATUS_AND_TOKEN)
      },

      canViewImagesVisibility() {
        return userHasPermission(USER_PERMISSIONS.PROPERTY_IMAGE.VIEW_PROPERTY_IMAGE)
      },

      canViewMilestone() {
        return userHasPermission(USER_PERMISSIONS.PROPERTY.VIEW_PROPERTY_MILESTONE)
      },

      canViewFinancials() {
        return userHasPermission(USER_PERMISSIONS.PROPERTY_FINANCIAL.VIEW_ALL_PROPERTY_FINANCIALS)
      },

      canViewPropertyDetail() {
        return userHasPermission(USER_PERMISSIONS.PROPERTY.VIEW_PROPERTY)
      },

      canUpdatePropertyDetail() {
        return userHasPermission(USER_PERMISSIONS.PROPERTY.UPDATE_PROPERY_DETAIL)
      },

    },
  }
  </script>
  <style scoped>
  .modal-header-title {
    margin: 0;
    font-weight: bold;
  }

  .close {
    color: #ffffff;
    opacity: .8;
    padding-bottom: 20px !important;
    /*!* opacity: .5; */
  }

  .images-picker {
    width: 60%;
  }

  .document-content {
    margin: 20px 0;
    padding-bottom: 20px;
  }

  .document-content li {
    list-style: none;
    color: #3a283d;
    margin: 3px 0;
    font-size: 16px;
  }

  .form-content label {
    font-weight: bold;
    font-size: 16px;
  }

  .capitalize:first-letter {
    text-transform: uppercase;
  }

  /*:deep() .mapboxgl-canvas {*/
  /*  width: 100% !important;*/
  /*  height: 100% !important;*/
  /*}*/
  </style>
