<template>
    <div>
        <div class="container">
            <b-form class="form-content" @submit.stop.prevent="onSubmit">
                <b-row class="mb-4">
                <b-col cols="6">
                    <label>Property name:</label>
                    <b-form-input v-model.trim="property.name" type="text" :state="validateState('name')"
                    placeholder="Property name" :disabled="isDisabledEditing">
                    </b-form-input>
                    <b-form-invalid-feedback>Name is a required field and must be between [2 200] characters.
                    </b-form-invalid-feedback>
                </b-col>
                <b-col cols="6">
                    <label>Spc:</label>
                    <b-form-select v-model="property.spc_id" :options="spcs" :state="validateState('spc_id')"
                                    text-field="company_name" value-field="id" :disabled="isDisabledEditing">
                    <template #first>
                        <b-form-select-option :value="null">Select Spc</b-form-select-option>
                    </template>
                    </b-form-select>
                    <b-form-invalid-feedback>Spc is a required field.</b-form-invalid-feedback>
                </b-col>
                </b-row>
                <b-row class="mb-4">
                <b-col cols="6">
                    <label>Property type:</label>
                    <b-form-select v-model="property.category_id" :state="validateState('category_id')" text-field="name"
                    value-field="id" :disabled="isDisabledEditing">
                    <template #first>
                        <b-form-select-option :value="null">Select type</b-form-select-option>
                    </template>
                    <template v-for="category in categories">
                        <b-form-select-option-group v-if="category.options && category.options.length" :label="category.name">
                        <b-form-select-option v-for="option in category.options" :key="option.id" :value="option.id">
                            {{ option.name }}
                        </b-form-select-option>
                        </b-form-select-option-group>
                        <b-form-select-option v-else :value="category.id">
                        {{ category.name }}
                        </b-form-select-option>
                    </template>

                    </b-form-select>
                    <b-form-invalid-feedback>Type is a required field.</b-form-invalid-feedback>
                </b-col>
                <b-col cols="6">
                    <label>Property location:</label>
                    <b-form-select v-model="property.location_id" :options="locations" :state="validateState('location_id')"
                    text-field="name" value-field="id" :disabled="isDisabledEditing">
                    <template #first>
                        <b-form-select-option :value="null">Select Location</b-form-select-option>
                    </template>
                    </b-form-select>
                    <b-form-invalid-feedback>Location is a required field.</b-form-invalid-feedback>
                </b-col>
                </b-row>
                <b-row class="mb-4">
                <b-col cols="6">
                    <label>Property address:</label>
                    <b-form-input v-model.trim="property.metadata.address" type="text" placeholder="Property address" :disabled="isDisabledEditing">
                    </b-form-input>
                </b-col>
                <b-col cols="6">
                    <label>Property sqm:</label>
                    <b-form-input v-model="property.sqm" :state="validateState('sqm')" type="number" placeholder="Property sqm" :disabled="isDisabledEditing">
                    </b-form-input>
                    <b-form-invalid-feedback>SQM is a required field.</b-form-invalid-feedback>
                </b-col>
                </b-row>
                <b-row class="mb-4">
                <b-col cols="6">
                    <label>Property bed:</label>
                    <b-form-input v-model="property.num_bed" type="number" placeholder="Property bed" :disabled="isDisabledEditing"></b-form-input>
                </b-col>
                <b-col cols="6">
                    <label>Property bath:</label>
                    <b-form-input v-model="property.num_bath" type="number" placeholder="Property bath" :disabled="isDisabledEditing"></b-form-input>
                </b-col>
                </b-row>
                <b-row class="mb-4">
                <b-col cols="6">
                    <label>Property progress:</label>
                    <b-input-group append="%">
                    <b-form-input v-model="property.progress" :state="validateState('progress')" type="number" append=".00"
                        placeholder="Property progress" :disabled="isDisabledEditing"></b-form-input>
                    </b-input-group>
                </b-col>
                <b-col cols="6">
                    <b-row>
                    <b-col>
                        <label>Property latitude:</label>
                        <b-form-input v-model="property.lat" type="number" placeholder="Property latitude" :disabled="isDisabledEditing"></b-form-input>
                    </b-col>
                    <b-col>
                        <label>Property longitude:</label>
                        <b-form-input v-model="property.lng" type="number" placeholder="Property longitude" :disabled="isDisabledEditing"></b-form-input>
                    </b-col>
                    </b-row>
                </b-col>
                </b-row>
                <b-row class="mb-4">
                <b-col>
                    <b-row>
                    <b-col>
                        <label>Weight:</label>
                        <b-form-input v-model="property.weight" :state="validateState('weight')" type="number"
                        placeholder="Weight" :disabled="isDisabledEditing">
                        </b-form-input>
                    </b-col>
                    <b-col>
                        <label>Display Milestone Count:</label>
                        <b-form-input v-model="property.display_milestones_count" :state="validateState('display_milestones_count')" type="number"
                        placeholder="Display Milestone Count" :disabled="isDisabledEditing">
                        </b-form-input>
                    </b-col>
                    </b-row>
                </b-col>
                </b-row>
                <b-row class="mb-4">
                <b-col>
                    <b-row>
                    <b-col>
                        <label>Property ERY:</label>
                        <b-input-group append="%">
                        <b-form-input v-model="property.ery" :state="validateState('ery')" type="number" append=".00"
                            placeholder="Property ERY" :disabled="isDisabledEditing"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col>
                        <label>Property ECA:</label>
                        <b-input-group append="%">
                        <b-form-input v-model="property.eca" :state="validateState('eca')" type="number" append=".00"
                            placeholder="Property ECA" :disabled="isDisabledEditing"></b-form-input>
                        </b-input-group>
                    </b-col>
                    </b-row>
                </b-col>
                </b-row>
                <b-row class="mb-4">
                <b-col cols="6">
                    <label>Map link:</label>
                    <b-form-input v-model="property.map_link" placeholder="Map link" :disabled="isDisabledEditing">
                    </b-form-input>
                </b-col>
                </b-row>
                <b-row class="mb-4 pt-4">
                <b-col cols="12">
                    <location-picker v-model="map.location" ref="locationPicker" :apiKey="apiKey" :geo="map.geo"
                    :searchForm="map.searchForm" :color="map.color" :initDelay="300" :disabled="isDisabledEditing"/>
                </b-col>
                </b-row>
                <b-form-group class="pb-5">
                <b-form-checkbox id="checkbox-drag-drop" v-model="checkDragDrop" name="checkbox-drag-drop"
                    value="enabled_drag_drop" unchecked-value="disabled_drag_drop" :disabled="isDisabledEditing">
                    Enable Drag & Drop Reordering
                </b-form-checkbox>
                <VueFileAgent v-model="images" v-model:rawModelValue="images" ref="vueFileAgent" :theme="'grid'" :multiple="true"
                    :deletable="checkDragDrop === 'disabled_drag_drop'"
                    :sortable="checkDragDrop === 'enabled_drag_drop'"
                    :meta="false" :accept="'image/*'" :maxSize="'3MB'" :maxFiles="10" :helpText="'Choose images files'"
                    :errorText="{
                        type: 'Invalid file type. Only images are allowed',
                        size: 'Files should not exceed 3MB in size',
                    }"
                    @beforedelete="onBeforeDelete($event)"
                    @select="filesSelected($event)"
                    @sort="onSort"
                    :disabled="isDisabledEditing">
                </VueFileAgent>
                </b-form-group>

                <b-tabs content-class="pt-2">
                  <b-tab active title="Detail" class="bg-white">
                      <editor :key="editorKey" :cloud-channel="tinyMCECloudChannel" id="editor-description" :api-key="tinyCloudKey"
                      :init="editor" :initial-value="property.metadata.detail" model-events="change keydown blur focus paste"
                      v-model="property.metadata.detail" :disabled="isDisabledEditing"/>
                  </b-tab>

                  <b-tab title="Detail (Indonesian)" class="bg-white">
                      <editor :key="editorKey" :cloud-channel="tinyMCECloudChannel" id="editor-description-id"
                      :api-key="tinyCloudKey" :init="editor" :initial-value="property.metadata.detail_id_locale"
                      model-events="change keydown blur focus paste" v-model="property.metadata.detail_id_locale" :disabled="isDisabledEditing"/>
                  </b-tab>

                  <b-tab title="Financials" class="bg-white">
                      <editor :key="editorKey" :cloud-channel="tinyMCECloudChannel" id="editor-financials" :api-key="tinyCloudKey"
                      :init="editor" :initial-value="property.metadata.financial" model-events="change keydown blur focus paste"
                      v-model="property.metadata.financial" :disabled="isDisabledEditing"/>
                  </b-tab>

                  <b-tab title="Financials (Indonesian)" class="bg-white">
                      <editor :key="editorKey" :cloud-channel="tinyMCECloudChannel" id="editor-financials-id"
                      :api-key="tinyCloudKey" :init="editor" :initial-value="property.metadata.financial_id_locale"
                      model-events="change keydown blur focus paste" v-model="property.metadata.financial_id_locale" :disabled="isDisabledEditing"/>
                  </b-tab>

                  <b-tab title="Documents" class="bg-white">
                      <div class="p-3">
                      <div class="mb-2">Select New Files</div>
                      <b-form-file class="btn btn-secondary btn-sm" v-model="documents" @input="onDocumentChange" multiple plain :disabled="isDisabledEditing">
                      </b-form-file>
                      <div class="document-content">
                          <ul>
                          <li v-for="thisFile in filesAccumulated">
                              <b-icon icon="file-earmark"></b-icon>
                              {{ thisFile.name }}
                          </li>
                          </ul>
                      </div>
                      <div class="mb-4" v-if="currentDocuments.length">
                          <div>Current Files</div>
                          <div class="d-flex flex-row align-items-center mt-2" v-for="(item, index) in currentDocuments">
                          <button size="sm" type="button" class="btn-outline-main pl-3 pr-3 mr-2"
                              @click="removeCurrentDocument(index)" :disabled="isDisabledEditing">x</button>
                          <div>{{ item.file_name }}</div>
                          </div>
                      </div>
                      <b-row class="mt-2">
                          <b-col cols="10" lg="11">
                          <div>External Links</div>
                          </b-col>
                          <b-col cols="2" lg="1" class="text-right">
                            <button size="sm" type="button" class="btn-outline-main pl-4 pr-4 mr-2" @click="addLink" :disabled="isDisabledEditing">+</button>
                          </b-col>
                      </b-row>
                      <LinkInput v-for="(item, index) in links" :link="item" :index="index" @removeLink="removeLink" :disabled="isDisabledEditing"/>
                      </div>
                  </b-tab>

                  <b-tab title="Market" class="bg-white">
                      <editor :key="editorKey" :cloud-channel="tinyMCECloudChannel" id="editor-market" :api-key="tinyCloudKey"
                      :init="editor" :initial-value="property.metadata.market" model-events="change keydown blur focus paste"
                      v-model="property.metadata.market" :disabled="isDisabledEditing"/>
                  </b-tab>

                  <b-tab title="Market (Indonesian)" class="bg-white">
                      <editor :key="editorKey" :cloud-channel="tinyMCECloudChannel" id="editor-market-id" :api-key="tinyCloudKey"
                      :init="editor" :initial-value="property.metadata.market_id_locale"
                      model-events="change keydown blur focus paste" v-model="property.metadata.market_id_locale" :disabled="isDisabledEditing"/>
                  </b-tab>
                </b-tabs>

            </b-form>
        </div>
        <div class="tab-footer d-flex flex-row align-items-center justify-content-end mt-3 mb-3">
          <b-button v-if="isEditing" class="m-1" size="md" variant="warning" @click="onSubmit(PROPERTY_APPROVAL_STATUS.DRAFT)">
            Save as Draft
          </b-button>

          <b-button class="m-1" v-if="!canApprovePropertyPublish && isEditing" size="md" variant="primary" @click="onSubmit(PROPERTY_APPROVAL_STATUS.PENDING)">
            Save & Submit for Approval
          </b-button>

          <b-button class="m-1" v-if="!canApprovePropertyPublish && !isEditing && propertyApprovalIsDraft" size="md" variant="primary" @click="onSubmit(PROPERTY_APPROVAL_STATUS.PENDING)">
            Submit for Approval
          </b-button>

          <b-button class="m-1" v-if="canPublish && isEditing" size="md" variant="success" @click="onShowConfirmPublish()">
            Save & Publish
          </b-button>
          
          <b-button class="m-1" v-if="canPublish && !isEditing && !propertyApprovalIsApproved" size="md" variant="success" @click="onShowConfirmPublish()">
            Publish
          </b-button>

          <b-button class="m-1" size="md" variant="secondary" @click="onCancel({ show: false })">
            Cancel
          </b-button>
        </div>
        <ModalConfirmPublishProperty :show="isConfirmModalVisible" @on-close="onCloseConfirm()" @on-confirm="onConfirm()"/>
    </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core'
  import { decimal, integer, maxLength, minLength, minValue, required } from '@vuelidate/validators'
  import Editor from "@anhoang/tinymce-vue"
  import locationsService from "@/services/locations.service"
  import categoryService from "@/services/categories.service"
  import propertiesService from "@/services/properties.service"
  import { getBase64, notify, urlImage, getTinyMCEApiKey, userHasPermission } from "@/helpers/common"
  import LocationPicker from "@/components/Maps/LocationPicker.vue"
  import LinkInput from '@/components/LinkInput.vue'
  import spcsService from "@/services/spcs.service"
  import { PROPERTY_STATUSES_LIST, PROPERTY_APPROVAL_STATUS } from "@/constants/constants"
  import { USER_PERMISSIONS } from "@/constants/permissions"
  import ModalConfirmPublishProperty from "@/pages/admin/modals/ModalConfirmPublishProperty.vue"

  export default {
    components: {
      LocationPicker,
      Editor,
      LinkInput,
      ModalConfirmPublishProperty,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      theme: {
        type: String,
        default: "light"
      },
      title: {
        type: String,
        default: ""
      },
      propertyId: {
        type: Number,
        default: null,
      },
      isEditing: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      return {
        v$: useVuelidate()
      }
    },
    data() {
      return {
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        showModal: false,
        map: {
          location: [],
          geo: true,
          searchForm: true,
          height: 500,
          color: "#66615b",
          initDelay: 1000,
        },
        property: {
          id: null,
          name: "",
          status: "",
          approval_status: PROPERTY_APPROVAL_STATUS.DRAFT,
          spc_id: null,
          category_id: null,
          location_id: null,
          num_bed: null,
          num_bath: null,
          sqm: null,
          lng: null,
          lat: null,
          documents: [],
          progress: null,
          weight: 0,
          ery: null,
          eca: null,
          display_milestones_count: 5,
          metadata: {
            address: '',
            detail: '',
            detail_id_locale: '',
            financial: '',
            financial_id_locale: '',
            market: '',
            market_id_locale: '',
          },
          map_link: null,
        },
        images: [],
        documents: [],
        currentDocuments: [],
        deletedFileIds: [],
        links: [],
        filesAccumulated: [],
        spcs: [],
        locations: [],
        categories: [],
        checkDragDrop: "disabled_drag_drop",
        editorKey: 0,
        tinyMCECloudChannel: '4',
        editor: {
          height: 500,
          menubar: true,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor textcolor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount"
          ],
          toolbar:
            "undo redo | formatselect | bold italic backcolor forecolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
        },
        PROPERTY_APPROVAL_STATUS: PROPERTY_APPROVAL_STATUS,
        isConfirmModalVisible: false,
      }
    },
    validations() {
      return {
        property: {
          name: {
            required,
            minLength: minLength(2),
            mexLength: maxLength(200)
          },
          category_id: {
            required,
          },
          spc_id: {
            required,
          },
          location_id: {
            required,
          },
          num_bed: {
            integer,
          },
          num_bath: {
            integer,
          },
          sqm: {
            required,
            integer,
          },
          progress: {
            decimal,
          },
          ery: {
            decimal,
          },
          eca: {
            decimal,
          },
          weight: {
            integer,
          },
          display_milestones_count: {
            integer,
          }
        },
        links: {
          $each: {
            link: {
              required
            },
            icon: {
              required
            },
            name: {
              required
            },
          }
        }
      }
    },
    watch: {
      "map.location"(v) {
        this.property.lat = v[1]
        this.property.lng = v[0]
      },
      async propertyId(v) {
        if (v) {
            await this.getProperty(v)
            this.editorKey += 1
        }
      },
      show(value) {
        this.showModal = value
        if (value) {
          setTimeout(() => {
            this.editorKey += 1
          }, 1000)
        }
      },
      'property.images': {
        handler(values) {
          if (values && values.length) {
            const images = []
            values.forEach(v => {
              if (v.image !== "") {
                const name = v.image.split("/").pop()
                const type = name.split(".").pop()
                images.push({
                  id: v.id,
                  tempName: name,
                  name: name,
                  url: urlImage(v),
                  path: urlImage(v),
                  size: 0,
                  type: `image/${type}`,
                })
              }
            })
            this.images = images
          }
        },
        deep: true
      },
      "property.documents"(values) {
        if (values && values.length) {
          const links = [];
          values.forEach(v => {
            if (v.id && v.file_name) {
              if (v.type === 'LINK') {
                links.push({
                  name: v.file_name,
                  link: v.link,
                  icon: v.icon,
                })
              }
            }
          })
          this.currentDocuments = values.filter(e => e.type === 'FILE')
          this.links = links
        }
      },
    },
    created() {
      this.onReset()
    },
    async mounted() {
      if (this.propertyId) {
        await this.getProperty(this.propertyId)
      }
      await Promise.all([
          this.getSpcs(),
          this.getLocations(),
          this.getCategories(),
      ])
    },
    methods: {
      onReset() {
        // Reset our form values
        this.property.id = null
        this.property.name = ""
        this.property.approval_status = PROPERTY_APPROVAL_STATUS.DRAFT
        this.property.spc_id = null
        this.property.category_id = null
        this.property.location_id = null
        this.property.num_bed = null
        this.property.num_bath = null
        this.property.sqm = null
        this.property.lng = null
        this.property.lat = null
        this.property.documents = []
        this.property.images = []
        this.property.progress = null
        this.property.ery = null
        this.property.eca = null
        this.property.map_link = null
        this.property.weight = 0
        this.property.display_milestones_count = 5
        this.documents = []
        this.currentDocuments = []
        this.deletedFileIds = []
        this.links = []
        this.filesAccumulated = []
        this.images = []
        this.checkDragDrop = "disabled_drag_drop"
        this.property.metadata = {
          address: '',
          detail: '',
          detail_id_locale: '',
          financial: '',
          financial_id_locale: '',
          market: '',
          market_id_locale: '',
        }
      },
      notAfterToday(date) {
        // const today = new Date().setHours(0, 0, 0, 0)
        return false //date > today
      },
      validateState(name) {
        const { $dirty, $error } = this.v$.property[name]
        return $dirty ? !$error : null
      },
      onCancel: function (v) {
        this.$emit("onCancel")
      },
      onClose: function (v) {
        this.$emit("onClose")
      },
      async onSubmit(approvalStatus = PROPERTY_APPROVAL_STATUS.DRAFT) {
        this.v$.$touch();
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) {
          window.scrollTo(0, 0)
          return;
        }

        if (approvalStatus && approvalStatus !== PROPERTY_APPROVAL_STATUS.APPROVED) {
          this.property.approval_status = approvalStatus
        }
        
        this.property.documents = []
        const uploadImages = this.images.map((e, index) => {
          if (e.id) {
            return {
              id: e.id,
              path: e.path,
              name: e.tempName,
              weight: index + 1,
            }
          }
          return {
            path: e.path,
            name: e.file.name,
            isNew: true,
            weight: index + 1,
          }
        })
        if (!uploadImages || !uploadImages.length) {
          notify({ text: "Property images is required", type: "error" })
          return
        }
        const values = []
        for (const file of this.documents) {
          if (file.name) {
            const base64 = await getBase64(file)
            if (base64) {
              values.push({
                name: file.name,
                data: base64
              })
            }
          }
        }
        if (values.length) {
          this.property.documents = values
        }

        const data = await propertiesService.saveDetails({
          ...this.property,
          links: this.links,
          images: uploadImages,
          deleted_file_ids: this.deletedFileIds,
        })
        if (data) {
          if (approvalStatus && approvalStatus === PROPERTY_APPROVAL_STATUS.APPROVED) {
            const published = await this.publishProperty()
            if (published) {
              this.onClose({
                show: false,
                reload: true,
              })
            }
          } else {
            this.onClose({
              show: false,
              reload: true,
            })
          }
        }
      },
      onDocumentChange(event) {
        const values = []
        this.documents.forEach(thisFile => {
          values.push({
            name: thisFile.name
          })
        })
        this.filesAccumulated = values
      },
      async getProperty(propertyId) {
        this.property = await propertiesService.getById(propertyId)
      },
      async getSpcs () {
        const response = await spcsService.getSpcs()
        if (response && response.data) {
          this.spcs = response.data
        }
      },
      async getLocations() {
        this.locations = await locationsService.getLocations()
      },
      async getCategories() {
        this.categories = await categoryService.getCategories()
      },

      onBeforeDelete(fileRecord) {
        const index = this.images.indexOf(fileRecord)
        if (index !== -1) {
          this.images.splice(index, 1)
        }
      },

      async filesSelected(fileRecords) {
        if (this.images.length) {
          for (let i = 0; i < this.images.length; i++) {
            if (!this.images[i].id && !this.images[i].path) {
              this.images[i].path = await getBase64(this.images[i].file)
            }
          }
        }
      },

      addLink() {
        this.links = [...this.links, {}]
      },

      removeLink(index) {
        this.links.splice(index, 1)
      },

      removeCurrentDocument(index) {
        const deleted = this.currentDocuments.splice(index, 1)
        if (deleted.length) {
          this.deletedFileIds.push(deleted[0].id)
        }
      },

      onSort(event, sortData) {},

      async publishProperty() {
        const data = await propertiesService.publishProperty({
          id: this.property.id
        })

        if (data) {
          this.onClose({
            show: false,
            reload: true,
          })
        }
      },
      onShowConfirmPublish() {
        this.isConfirmModalVisible = true
      },
      onCloseConfirm() {
        this.isConfirmModalVisible = false
      },
      async onConfirm() {
        this.isConfirmModalVisible = false
        if (this.isEditing && this.canUpdatePropertyDetail) {
          await this.onSubmit(PROPERTY_APPROVAL_STATUS.APPROVED)
        } else {
          await this.publishProperty()
        }
      },
    },
    computed: {
      apiKey() {
        return process.env.VUE_APP_MAP_KEY
      },

      isDisabledEditing() {
        return !this.isEditing
      },

      tinyCloudKey() {
        return getTinyMCEApiKey()
      },

      titleModal() {
        if (this.canApprovePropertyPublish && this.property.approval_status === PROPERTY_APPROVAL_STATUS.PENDING) {
          return 'Approve Property'
        } 
        return this.title
      },

      canApprovePropertyPublish() {
        return userHasPermission(USER_PERMISSIONS.PROPERTY.APPROVE_PROPERTY_PUBLISH)
      },

      canPublish() {
        const hasApprovePermission = userHasPermission(USER_PERMISSIONS.PROPERTY.APPROVE_PROPERTY_PUBLISH)
        if (hasApprovePermission) {
          if (this.property.approval_status === PROPERTY_APPROVAL_STATUS.PENDING) {
            return true
          } else {
            const hasCreateOrUpdatePermission = userHasPermission(USER_PERMISSIONS.PROPERTY.CREATE_PROPERTY) || userHasPermission(USER_PERMISSIONS.PROPERTY.UPDATE_PROPERTY)
            if (hasCreateOrUpdatePermission) {
              return true
            }
          }          
        }
        return false
      },
      
      canUpdatePropertyDetail() {
        return userHasPermission(USER_PERMISSIONS.PROPERTY.UPDATE_PROPERY_DETAIL)
      },

      propertyApprovalIsDraft() {
        return this.property.approval_status === PROPERTY_APPROVAL_STATUS.DRAFT
      },

      propertyApprovalIsPending() {
        return this.property.approval_status === PROPERTY_APPROVAL_STATUS.PENDING
      },

      propertyApprovalIsApproved() {
        return this.property.approval_status === PROPERTY_APPROVAL_STATUS.APPROVED
      },
    },
  }
  </script>
  <style scoped>
  .modal-header-title {
    margin: 0;
    font-weight: bold;
  }

  .close {
    color: #ffffff;
    opacity: .8;
    padding-bottom: 20px !important;
    /*!* opacity: .5; */
  }

  .images-picker {
    width: 60%;
  }

  .document-content {
    margin: 20px 0;
    padding-bottom: 20px;
  }

  .document-content li {
    list-style: none;
    color: #3a283d;
    margin: 3px 0;
    font-size: 16px;
  }

  .form-content label {
    font-weight: bold;
    font-size: 16px;
  }

  .capitalize:first-letter {
    text-transform: uppercase;
  }

  /*:deep() .mapboxgl-canvas {*/
  /*  width: 100% !important;*/
  /*  height: 100% !important;*/
  /*}*/
  </style>
