<template>
    <div class="financial-item">
        <div v-if="!disabled" class="text-right">
            <button id="btn_removeFinancial" size="sm" type="button" class="btn-outline-main pl-3 pr-3" @click="removeFinancial(index)" :disabled="disabled">x</button>
        </div>

        <label>Subheader:</label>
        <b-form-select v-model="financial.subheader" :state="validateFinancial(index, 'subheader')"
            :options="subheaderList" :disabled="disabled">
        </b-form-select>

        <label class="mt-3">Key:</label>
        <b-form-input :id="`key-${index}`" v-model="financial.key" :state="validateFinancial(index, 'key')"
            placeholder="Key" :disabled="disabled">
        </b-form-input>

        <label class="mt-3">Key - ID Locale:</label>
        <b-form-input :id="`key-id-locale-${index}`" v-model="financial.key_id_locale"
            :state="validateFinancial(index, 'key_id_locale')" placeholder="Key ID Locale" :disabled="disabled">
        </b-form-input>

        <label class="mt-3">Value:</label>
        <b-form-input :id="`value-${index}`" v-model="financial.value" :state="validateFinancial(index, 'value')"
            type="number" placeholder="Value" :disabled="disabled">
        </b-form-input>

        <label class="mt-3">Priority:</label>
        <b-form-input :id="`priority-${index}`" v-model="financial.priority" :state="validateFinancial(index, 'priority')"
            type="number" placeholder="Priority" :disabled="disabled">
        </b-form-input>

        <label class="mt-3">Description:</label>
        <b-form-textarea :id="`description-${index}`" v-model="financial.description" rows="3" max-rows="6"
            placeholder="Description" :disabled="disabled">
        </b-form-textarea>

        <label class="mt-3">Description - ID Locale:</label>
        <b-form-textarea :id="`description-id-locale-${index}`" v-model="financial.description_id_locale" rows="3"
            max-rows="6" placeholder="Description ID Locale" :disabled="disabled">
        </b-form-textarea>
    </div>
</template>
  
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minValue } from '@vuelidate/validators'
import { FINANCIAL_SUBHEADER } from "@/constants/constants";

export default {
    props: {
        financial: { type: Object, required: true },
        index: { type: Number, required: true },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            subheaderList: [
                { text: 'Subheader', value: '' },
                { text: 'Asset value', value: FINANCIAL_SUBHEADER.ASSET_VALUE },
                { text: 'Annual return', value: FINANCIAL_SUBHEADER.ANNUAL_RETURN },
            ]
        }
    },
    methods: {
        validateFinancial(index, field) {
            const { $dirty, $error } = this.v$.financial[field]
            return $dirty ? !$error : null
        },
        removeFinancial(index) {
            this.$emit('removeFinancial', index)
        },
    },
    validations: {
        financial: {
            subheader: {
                required
            },
            key: {
                required
            },
            key_id_locale: {
                required
            },
            value: {
                required
            },
            priority: {
                required,
                minValue: (value) => value > 0,
            },
        }
    }
}
</script>