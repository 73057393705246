<template>
    <div class="mt-2 p-2">
        <b-row align-v="center">
            <b-col cols="10" lg="11">
                <b-row align-v="center">
                    <b-col cols="12" lg="8">
                        <b-row>
                            <b-col cols="12">
                                <b-form-input v-model="link.link" placeholder="Link"
                                    :state="validateLinks(index, 'link')" :disabled="disabled"></b-form-input>
                            </b-col>
                            <b-col cols="12" class="mt-1">
                                <b-form-input v-model="link.icon" placeholder="Icon"
                                    :state="validateLinks(index, 'icon')" :disabled="disabled"></b-form-input>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" lg="4" class="mt-1 mt-lg-0">
                        <b-form-input v-model="link.name" placeholder="File name or Folder name"
                            :state="validateLinks(index, 'name')" :disabled="disabled"></b-form-input>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="2" lg="1" class="text-right">
                <button id="removeLink" size="sm" type="button" class="btn-outline-main pl-3 pr-3 mr-2"
                    @click="removeLink(index)" :disabled="disabled">x</button>
            </b-col>
        </b-row>
    </div>
</template>
  
<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
    props: {
        link: { type: Object, required: true },
        index: { type: Number, required: true },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        return { v$: useVuelidate() }
    },
    methods: {
        validateLinks(index, field) {
            const { $dirty, $error } = this.v$.link[field]
            return $dirty ? !$error : null
        },
        removeLink(index) {
            this.$emit('removeLink', index)
        },
    },
    validations: {
        link: {
            link: { required },
            icon: { required },
            name: { required },
        }
    }
}
</script>