<template>
  <b-modal v-model="isShowOnboarding" centered="true" modal-class="goro-onboarding-quiz-modal" id="id-goro-onboarding-quiz-modal" hide-header hide-header-close hide-footer no-close-on-backdrop no-close-on-esc>
    <!-- Show welcome -->
    <div v-if="isShowWelcomeQuiz" class="cls-quiz cls-quiz-welcome">
      <div class="custom-modal-header">
        <div class="text-center mb-2">
          <img width="155" :src="require(`@/assets/img/logo_with_text.png`)" alt="GORO Quiz"/>
        </div>
      </div>
      <div class="custom-modal-content pt-3 pb-3">
        <div class="text-center">
          <img width="220" height="230" :src="require(`@/assets/img/quiz_welcome.png`)" alt="GORO Quiz"/>
          <p class="font-20 welcome-message mt-4 mb-4">
            {{ $t("ONBOARDING.WELCOME_MESSAGE") }}
          </p>
        </div>
      </div>
      <div class="custom-modal-footer">
        <b-button class="btn-main btn-quiz font-14" @click="startQuiz()">
          {{ $t("ONBOARDING.START") }}
        </b-button>
      </div>
    </div>
    <!-- End Show welcome -->

    <!-- Show answers -->
    <div v-for="(quiz, index) in quizzes" :key="index" class="cls-quizzes">
      <div v-if="showQuiz(index)" class="cls-quiz">
        <div class="custom-modal-header">
          <h2 class="heading font-24 mb-4">
            {{ $t("ONBOARDING.QUIZ_HEADING") }}
          </h2>
          <p class="step-question font-16 mt-3">
            {{ $t("ONBOARDING.STEP_QUESTION") }} <strong>{{ `${currentIdxQuiz}/${totalQuizzes}` }}</strong>
          </p>
        </div>
        <div class="custom-modal-content pt-3">
          <div class="question-block">
            <p class="question font-20">
              {{ quiz.question[currentLocale] }}
            </p>
          </div>
          <div class="answer-items mt-4">
            <b-button-group v-if="quiz.answers.length > 0" vertical>
              <b-button v-for="(answer, index) in quiz.answers" :key="index" class="answer-item font-15" @click="submitQuiz(answer, quiz.id)">
                {{ answer[currentLocale] }}
              </b-button>
            </b-button-group>
          </div>
        </div>
      </div>
    </div>
    <!-- End Show answers -->

    <!-- Incorrect -->
    <div v-if="showInCorrect" class="cls-quiz cls-quiz-show-message cls-quiz-incorrect">
      <div class="custom-modal-header">
        <h2 class="heading font-24 incorrect show-desktop">
          {{ incorrectTitle }}
        </h2>
      </div>
      <div class="custom-modal-content pt-3 pb-3">
        <div class="text-center">
          <img width="156" height="156" :src="require(`@/assets/img/quiz_icon_incorrect.png`)" alt="GORO Quiz" class="cls-icon"/>
          <h2 class="heading font-24 incorrect show-mobile">
            {{ incorrectTitle }}
          </h2>
          <p class="font-18 message mt-5">
            {{ incorrectMessage }}
          </p>
        </div>
      </div>
      <div class="custom-modal-footer mt-3">
        <b-button class="btn-main btn-quiz font-14" @click="tryAgain()">
          {{ $t("ONBOARDING.TRY_AGAIN") }}
        </b-button>
      </div>
    </div>
    <!-- End Incorrect -->

    <!-- Correct -->
    <div v-if="showCorrect" class="cls-quiz cls-quiz-show-message cls-quiz-correct">
      <div class="custom-modal-header">
        <h2 class="heading font-24 incorrect show-desktop">
          {{ correctTitle }}
        </h2>
      </div>
      <div class="custom-modal-content pt-3 pb-3">
        <div class="text-center">
          <img width="156" height="156" :src="require(`@/assets/img/quiz_icon_correct.png`)" alt="GORO Quiz" class="cls-icon"/>
          <h2 class="heading font-24 incorrect show-mobile">
            {{ correctTitle }}
          </h2>
          <p class="font-18 message mt-5">
            {{ correctMessage }}
          </p>
        </div>
      </div>
      <div class="custom-modal-footer mt-3">
        <b-button class="btn-main btn-quiz font-14" @click="nextQuiz()">
          {{ $t("ONBOARDING.NEXT") }}
        </b-button>
      </div>
    </div>
    <!-- End Correct -->

    <!-- Show finished -->
    <div v-if="isShowFinishedQuiz" class="cls-quiz cls-quiz-welcome">
      <div class="custom-modal-header">
        <h2 class="heading font-24 welcome">
          {{ $t("ONBOARDING.CONGRATULATIONS") }}
        </h2>
      </div>
      <div class="custom-modal-content pt-3 pb-3">
        <div class="text-center">
          <img width="283" height="305" :src="require(`@/assets/img/quiz_finished.png`)" alt="GORO Quiz"/>
          <p class="font-18 welcome-message finished mt-4 mb-4">
            {{ $t("ONBOARDING.FINISHED_MESSAGE", { members: telegramMembers }) }} <a :href="telegramChanel" target="_blank">{{ $t("ONBOARDING.CLICK_HERE") }}</a>
          </p>
        </div>
      </div>
      <div class="custom-modal-footer">
        <b-button class="btn-main btn-quiz font-14" @click="finishedQuiz()">
          {{ $t("ONBOARDING.FINISHED") }}
        </b-button>
      </div>
    </div>
    <!-- End Show finished -->
  </b-modal>
</template>

<script>
import quizService from "../../services/quiz.service"
import i18n from "../../i18n"
import externalSites from "../../constants/externalSites"
import { FOREIGNER } from "../../constants/constants"
import { shuffleArray } from '../../helpers/common'
import store from "../../store/store"

export default {
  data() {
    return {
      quizzes: [],
      currentIdxQuiz: 0,
      totalQuizzes: 0,
      showCorrect: false,
      showInCorrect: false,
      isShowWelcomeQuiz: false,
      isShowFinishedQuiz: false,
      allowedShowOnboarding: false,
      incorrectMessage: "Your answer is incorrect!",
      correctMessage: "Your answer is correct!",
      telegramChanel: externalSites.TELEGRAMS.DEFAULT,
      answers: [],
    };
  },
  computed: {
    hasShowQuizItem() {
      return this.currentIdxQuiz && (this.currentIdxQuiz <= this.totalQuizzes)
    },
    isShowOnboarding() {
      return this.allowedShowOnboarding && (this.isShowWelcomeQuiz || this.hasShowQuizItem)
    },
    currentLocale() {
      const locale = i18n.global.locale.value
      if (!locale || locale === FOREIGNER.LOCALE) {
        return "en"
      }
      return locale.toLocaleLowerCase()
    },
    telegramMembers () {
      let shortNumber = 15
      if (store.state.configs && store.state.configs.number_of_telegram_members) {
        shortNumber = store.state.configs.number_of_telegram_members
      }
      return shortNumber
    },
    incorrectTitle() {
      if (this.currentIdxQuiz === this.totalQuizzes) {
        return this.$t("ONBOARDING.NOT_QUITE")
      } else {
        return this.$t("ONBOARDING.INCORRECT")
      }
    },
    correctTitle() {
      if (this.currentIdxQuiz === this.totalQuizzes) {
        return this.$t("ONBOARDING.GREAT_JOB")
      } else {
        return this.$t("ONBOARDING.CORRECT")
      }
    },
  },
  methods: {
    doNotShowQuiz() {
      return this.isShowWelcomeQuiz || this.showCorrect || this.showInCorrect || this.isShowFinishedQuiz
    },
    showQuiz(idx = 0) {
      if (this.doNotShowQuiz()) {
        return false
      }
      const itemIdx = (this.totalQuizzes - this.quizzes.length) + idx
      return (itemIdx + 1) === this.currentIdxQuiz
    },
    startQuiz() {
      this.isShowWelcomeQuiz = false
    },
    tryAgain() {
      this.resetShowing()
    },
    nextQuiz() {
      this.resetShowing()
      if (this.currentIdxQuiz === this.totalQuizzes) {
        this.isShowFinishedQuiz = true
        this.isShowWelcomeQuiz = false
      } else {
        this.currentIdxQuiz += 1
      }
    },
    finishedQuiz() {
      this.resetShowing()
      this.allowedShowOnboarding = false
    },
    async getQuiz() {
      const res = await quizService.getQuiz()
      if (res && res.data && res.total > 0) {
        this.totalQuizzes = res.total
        this.quizzes = res.data.map(item => {
          let answers = []
          // Iterate over the keys of the object
          Object.keys(item).forEach(key => {
            if (key.includes("answer_")) {
              // Create an object with the answer's letter as the key and its value
              let answerKey = key.split("_")[1].toUpperCase() // Extract the letter and convert to uppercase
              answers.push({
                answerKey: answerKey,
                ...item[key]
              })
            }
          });

          // Return the new object structure
          return {
            ...item,
            answers: shuffleArray(answers)
          }
        })

        this.currentIdxQuiz = (this.totalQuizzes - this.quizzes.length) + 1

        if (this.totalQuizzes === this.quizzes.length) {
          this.isShowWelcomeQuiz = true
        }
      }
    },
    resetShowing() {
      this.showCorrect = false
      this.showInCorrect = false
      this.isShowFinishedQuiz = false
      this.isShowWelcomeQuiz = false
    },
    async submitQuiz(answer, quizId) {
      if (!answer || !answer.answerKey || !quizId) {
        return false
      }

    this.resetShowing()
    try {
      const response = await quizService.answerQuiz({
        quiz_id: quizId,
        answer: answer.answerKey,
      })

      if (response) {
        if (response.correct) {
          this.showCorrect = true
          this.correctMessage = response.message
        } else {
          this.showInCorrect = true
          this.incorrectMessage = response.message
        }
      }
      } catch (e) {
        this.showInCorrect = true
        this.incorrectMessage = "There was an error submitting the answer. Please try again later."
      }
    },
  },
  watch: {
    async $route(to) {
      this.allowedShowOnboarding = false
      if (to && to.meta && to.meta.showQuiz) {
        await this.getQuiz()
        setTimeout(() => {
          this.allowedShowOnboarding = true
        }, 1000)
      }
    }
  }
};
</script>

<style lang="scss">
.goro-onboarding-quiz-modal{
  .modal-dialog{
    max-width: 556px !important;
    .modal-content{
      background-color: #fff;
      color: var(--primary-color);
      border-radius: 25px;
      text-align: center;
      *{
        font-family: "AcuminVariableConcept", Helvetica, sans-serif;
      }
      .modal-body{
        padding: 32px 48px 32px 48px !important;
        .custom-modal-header{
          .heading{
            margin-top: 0;
            font-weight: 600;
            line-height: 28.8px;
            text-align: center;

            &.welcome{
              color: #00918E;
            }
          }
        }
        .custom-modal-content{
        }
        .custom-modal-footer{

        }
        .cls-quiz-welcome{
          .custom-modal-header{
            .heading{
              color: #00918E;
            }
          }
          .custom-modal-content{
            .welcome-message{
              font-weight: 500;
              line-height: 21.6px;
              text-align: center;
              color: #00918E;
              &.finished{
                color: #303030;
              }
            }
          }
        }
        .cls-quizzes{
          .cls-quiz{
            .custom-modal-header{
              .heading{
                font-weight: 600;
                line-height: 28.8px;
                text-align: center;
                color: #00918E;
              }
              .step-question{
                font-weight: 500;
                line-height: 14.4px;
                text-align: center;
                color: #303030;
                @media screen and (max-width: 768px) {
                  text-align: right;
                }
              }
            }
            .custom-modal-content{
              .question-block{
                background-color: #00918E;
                padding: 60px 28px;
                border-radius: 10px;
                box-shadow: 0px 20px 50px -10px #00000040;
                .question{
                  font-weight: 500;
                  line-height: 24px;
                  text-align: center;
                  color: #fff;
                }
              }
              .answer-items{
                .btn-group-vertical{
                  width: 100%;
                }
                .answer-item{
                  min-height: 55px;
                  background-color: #fff;
                  width: 100%;
                  padding-top: 10px;
                  margin: 10px 0;
                  border: 2px solid transparent;
                  // box-shadow: 0px 1px 4px 0px #00000040;
                  box-shadow: 0px 0px 6.2px 0px #00000040;
                  border-radius: 9px;
                  font-weight: 500;
                  line-height: 18px;
                  text-align: center;
                  color: #00918E;
                  &:hover,
                  &:focus,
                  &:active{
                    border: 2px solid #00918E;
                    box-shadow: 0px 0px 6.2px 0px #00000040;
                    // background-color: #00918E !important;
                    // color: #fff !important;
                  }
                }
              }
            }
            .custom-modal-footer{

            }
          }
        }
        .cls-quiz-show-message{
          &.cls-quiz-incorrect{
            .heading{
              color: #CD1400;
            }
          }
          &.cls-quiz-correct{
            .heading{
              color: #00AD00;
            }
          }
          .custom-modal-content{
            .message{
              font-weight: 500;
              line-height: 21.6px;
              text-align: center;
              color: #303030;
            }
          }
          .custom-modal-content{
            img.cls-icon{
              @media screen and (max-width: 768px) {
                width: 110px;
                height: 110px;
              }
            }
          }
        }
      }
    }

  }
  .btn-quiz{
    font-weight: 600;
    line-height: 16.8px;
    text-align: center;
    height: 48px;
    border-radius: 8px;
    border-color: #00918E;
    background-color: #00918E;
    color: #fff;
    padding: 14px 40px 10px 40px;
    &:hover,
    &:focus,
    &:active{
      border-color: #00918E !important;
      background-color: #00918E !important;
      opacity: .9;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}
</style>
