<template>
  <div class="locale-switcher" v-if="this.$i18n.locale !== foreignerLocale">
    <b-dropdown toggle-class="dropdown-toggle-custom" variant="none" no-caret class="goro-dropdown-site-menu-custom goro-dropdown-locale-switcher">
      <template #button-content>
        <img class="ml-1 mr-2" :src="currentLanguageIcon" style="width: 24px; height: 24px" alt="Language" />
        <b-icon class="icon pt-1" icon="chevron-down" style="color: var(--primary-color);"></b-icon>
      </template>

      <b-dropdown-item v-for="(language) in languages" @click="onLanguageSelected(language.code)" class="sub-item" :class="currentLanguageCode === language.code ? 'active' : ''">
        <div class="d-flex flex-row align-items-center">
          <img class="ml-1 mr-2" :src="language.icon" style="width: 24px; height: 24px" alt="•" />
          <p class="font-12 p-0 m-0">{{ language.name }}</p>
          <b-icon v-if="currentLanguageCode === language.code" class="icon-item pt-1 ml-1" icon="check2"
            style="color: var(--primary-color);"></b-icon>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>

import authService from "../services/auth.service"
import { isLoggedIn } from "../constants/userStatus"
import { FOREIGNER } from "../constants/constants"

export default {
  data() {
    return {
      foreignerLocale: FOREIGNER.LOCALE,
      languages: [
        {
          code: "en",
          name: "English",
          icon: require("@/assets/img/flags/en.svg")
        },
        {
          code: "id",
          name: "Indonesian",
          icon: require("@/assets/img/flags/id.svg")
        }]
    }
  },

  methods: {
    onLanguageSelected: function (languageCode) {
      this.$i18n.locale = languageCode
      localStorage.setItem('preferred_locale', languageCode)
      this.updateLocale(languageCode);
    },

    async updateLocale(locale) {
      if (isLoggedIn()) {
        await authService.updateLocale({
          preferred_locale: locale === FOREIGNER.LOCALE ? "en" : locale,
        });
        this.$store.dispatch('refreshUserProfile');
      }
    },
  },

  computed: {
    currentLanguageCode() {
      return this.$i18n.locale
    },
    currentLanguageIcon() {
      return require(`@/assets/img/flags/${this.$i18n.locale}.svg`)
    },
  },
}
</script>
<style lang="scss">
.goro-dropdown-locale-switcher{
  .dropdown-toggle-custom{
    @media(max-width: 991px) {
      padding: 0.375rem 0.35rem;
    }
  }
}
</style>