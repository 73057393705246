<template>
    <div>
        <div class="container">
            <p class="font-14">Enable/Disable images will be used on referral page</p>
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-row class="pl-3 pr-3 mt-2" align-v="center" v-for="item in imageItems">
                    <img class="property-img" :src="getUrl(item)" />
                    <b-form-checkbox :id="`checkbox-${item.id}`" class="ml-3" v-model="item.visible"
                        :name="`checkbox-${item.id}`" :value="true" :unchecked-value="false" :disabled="isDisabledEditing">
                        {{ item.visible ? 'Enabled' : 'Disabled' }}
                    </b-form-checkbox>
                </b-row>
            </form>
        </div>
        <div class="tab-footer d-flex flex-row align-items-center justify-content-end mt-3 mb-3">
            <b-button v-if="isEditing && canUpdateImage" class="m-1" size="md" variant="success" @click="handleSubmit()">
                Save
            </b-button>
            <b-button class="m-1" size="md" variant="secondary" @click="$emit('onCancel')">
                Cancel
            </b-button>
        </div>
    </div>
</template>

<script>

import { urlImage } from "@/helpers/common"
import imageService from "@/services/admin/image.service"
import { userHasPermission } from "@/helpers/common"
import { USER_PERMISSIONS } from "@/constants/permissions"

export default {
    props: {
        propertyId: {
            type: Number,
            default: null,
        },
        isEditing: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            form: [],
            imageItems: [],
        }
    },

    async mounted() {
        if (this.propertyId) {
            await this.getPropertyImages(this.propertyId)
        }
    },

    methods: {
        resetModal() {
            this.form = []
        },

        async getPropertyImages(propertyId) {
            const res = await imageService.getPropertyImages({ property_id: propertyId })
            if (res && res.data) {
                const images = res.data
                this.imageItems = images.map(e => {
                    return {
                        id: e.id,
                        image: e.image,
                        visible: e.visible_on_prefer,
                    }
                })
            }
        },

        async handleSubmit() {
            const images = this.imageItems.map(e => {
                return {
                    id: e.id,
                    visible: e.visible,
                }
            })
            const res = await imageService.updatePreferImages({ images })
        },

        getUrl(image) {
            return urlImage(image)
        },
    },

    computed: {
        title() {
            return 'Images Visibility';
        },

        isDisabledEditing() {
            return !this.isEditing
        },

        canUpdateImage() {
          return userHasPermission(USER_PERMISSIONS.PROPERTY_IMAGE.UPDATE_IMAGE)
        },
    },
}
</script>

<style lang="scss" scoped>
.property-img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    border: 1px solid rgb(204, 204, 204);
}
</style>