<!--suppress HtmlUnknownTag -->
<template>
  <table class="table" :class="tableClass">
    <thead>
      <slot name="columns">
        <th v-for="column in columns" :key="column">{{ column }}</th>
        <th v-if="customActionTitles.length" key="customActions"></th>
        <th v-if="showActionColumn" key="action">Action</th>
      </slot>
    </thead>
    <slot name="theadSearch"></slot>
    <tbody>
      <tr v-for="(item, index) in data" :key="index" :class="trClassRender(item)" @click="onRowClicked(item.id, item)">
        <slot :row="item">
          <template v-for="(column, index) in columns" :key="index">
            <td v-if="hasValue(item, column)">
              <slot :name="'cell-' + column.toLowerCase()" :item="item">
                <div class="html-content" v-if="isHtml(index)" v-html="itemValue(item, column)"></div>
                <span v-else-if="!isImage(index)">{{ itemValue(item, column) }}</span>
                <img class="tb-img" v-if="isImage(index)" :src="itemValue(item, column)" />
              </slot>
            </td>
          </template>
        </slot>
        <td v-if="customActionTitles.length" class="text-center" :style="tdCustomActionStyle">
          <div class="d-flex flex-column align-items-center">
            <b-button v-for="(action, index) in filteredCustomActions(item)" class="pl-2 pr-2 mt-1 font-12"
              variant="none" @click="onCustomAction(item.id, action.key)" :class="action.btnClass ? action.btnClass : 'btn-outline-main'">
              {{ action.label }}
            </b-button>
          </div>
        </td>
        <td v-if="showActionColumn">
          <button v-if="showEditAction" title="Edit" @click="onModalEdit(item.id)" class="btn-icon-ct mr-2">
            <b-icon icon="pencil-square" scale="1.2" variant="primary"></b-icon>
          </button>
          <button v-if="showDeleteAction" title="Delete" @click="onModalDelete({ show: true, content: item.name, id: item.id })"
            class="btn-icon-ct">
            <b-icon icon="trash" scale="1.2" variant="danger"></b-icon>
          </button>
          <button v-if="showCopyAction" title="Copy" @click="onCopy(item)" class="btn-icon-ct mr-2">
            <b-icon icon="clipboard" scale="1.2" variant="primary"></b-icon>
          </button>
        </td>
      </tr>
      <tr v-if="data && data.length === 0">
        <td colspan="13">No records!</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import moment from 'moment';

export default {
  name: 'paper-table',
  props: {
    columns: Array,
    data: Array,
    type: {
      type: String, // striped | hover
      default: "striped"
    },
    title: {
      type: String,
      default: ""
    },
    subTitle: {
      type: String,
      default: ""
    },
    customActionTitles: {
      type: Array,
      default() {
        return []
      },
    },
    showAction: {
      type: Boolean,
      default: false,
    },
    showCopyAction: {
      type: Boolean,
      default: false,
    },
    showEditAction: {
      type: Boolean,
      default: false,
    },
    showDeleteAction: {
      type: Boolean,
      default: false,
    },
    imageColumns: {
      type: Array,
      default() {
        return []
      },
    },
    htmlColumns: {
      type: Array,
      default() {
        return []
      },
    },
    trClass: {
      type: String,
      default: ""
    },
    trClassField: {
      type: String,
      default: ""
    },
    tdCustomActionStyle: {
      type: Object,
      default: {}
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    hover: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tableClass() {
      let tableCls = `table-${this.type}`

      if (this.borderless) {
        tableCls = `${tableCls} table-borderless`
      }

      if (this.hover) {
        tableCls = `${tableCls} table-hover`
      }

      return `${tableCls}`
    },
    showActionColumn() {
      return this.showAction || this.showEditAction || this.showDeleteAction || this.showCopyAction
    },
  },
  methods: {
    onModalEdit: function (data) {
      this.$emit('onModalEdit', data);
    },
    onModalDelete: function (data) {
      this.$emit('onModalDelete', data);
    },
    onCopy: function (data) {
      this.$emit('onCopy', data);
    },
    onCustomAction: function (data, index) {
      this.$emit('onCustomAction', data, index);
    },
    onRowClicked(data, item) {
      this.$emit('onRowClicked', data, item);
    },
    hasValue(item, column) {
      return item[column.toLowerCase().replaceAll(' ', '_')] !== "undefined";
    },
    itemValue(item, column) {
      const columnName = column.toLowerCase().replaceAll(' ', '_');
      if (columnName === "created_at" || columnName === "updated_at") {
        return moment(item[columnName]).format("DD/MM/YYYY HH:mm:ss");
      }
      return item.hasOwnProperty(columnName) ? item[columnName] : "";
    },
    isImage(index) {
      return this.imageColumns.includes(index)
    },
    isHtml(index) {
      return this.htmlColumns.includes(index)
    },
    trClassRender(item) {
      let trClassName = `${this.trClass}`
      if (this.trClassField && this.trClassField !== "") {
        trClassName = `${trClassName} ${this.trClassField}-${this.itemValue(item, this.trClassField)}`
      }

      return trClassName
    },
    filteredCustomActions(item) {
      return this.customActionTitles.flatMap(action => {
        if (action.showConditions) {
          const { key, op, value, then } = action.showConditions
          const conditionValue = item[key]
          const isConditionMet = this.evaluateCondition(conditionValue, op, value)

          if (isConditionMet) {
            return then
          }
        }
        return action
      })
    },
    evaluateCondition(value, op, comparisonValue) {
      switch (op) {
        case '===': return value === comparisonValue
        case '!==': return value !== comparisonValue
        case '<': return value < comparisonValue
        case '>': return value > comparisonValue
        case '<=': return value <= comparisonValue
        case '>=': return value >= comparisonValue
        default: return false
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-icon-ct {
  padding: 2px;
  border: none;
  background-color: transparent;
}

.tb-img {
  width: 200px;
}

table.table tbody > tr.status-draft {
  background-color: #FFFBA6 !important;
}

.html-content {
  max-height: 400px;
  overflow: auto;
}
</style>
