<template>
    <div class="">
        <div class="container d-flex flex-column align-items-center">
            <FinancialInput v-for="(item, index) in financials" :key="item.id" :financial="item" :index="index"
                @removeFinancial="removeFinancial" :disabled="isDisabledEditing"/>
            <p v-if="!financials.length" class="mt-3 mb-4">There are no financials</p>
            <button v-if="!isDisabledEditing" size="sm" type="button" class="btn-outline-main pl-4 pr-4" @click="addFinancial" :disabled="isDisabledEditing">+ Add</button>
        </div>
        <div class="tab-footer d-flex flex-row align-items-center justify-content-end mt-3 mb-3">
            <b-button v-if="isEditing && canCreateOrUpdateFinancials" class="m-1" size="md" variant="success" @click="handleSubmit()">
                Update
            </b-button>
            <b-button class="m-1" size="md" variant="secondary" @click="$emit('onCancel')">
                Cancel
            </b-button>
        </div>
    </div>

</template>

<script>

    import propertiesService from '@/services/admin/properties.service';
    import { useVuelidate } from '@vuelidate/core'
    import { required } from '@vuelidate/validators'
    import FinancialInput from '@/components/FinancialInput.vue'
    import { userHasPermission } from "@/helpers/common"
    import { USER_PERMISSIONS } from "@/constants/permissions"

    export default {
        props: {
            propertyId: {
                type: Number,
                default: null,
            },
            isEditing: {
                type: Boolean,
                default: false,
            },
        },
        components: {
            FinancialInput,
        },
        emits: ['on-close'],
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        data() {
            return {
                showModal: false,
                financials: [],
                deletedIds: [],
            }
        },

        validations: {
            financials: {
                required,
            }
        },

        watch: {
            show(value) {
                this.showModal = value
            },
        },

        async mounted() {
            this.resetModal();
            if (this.propertyId) {
                await this.getFinancials()
            }
        },

        methods: {
            async getFinancials() {
                const res = await propertiesService.getFinancials({
                    property_id: this.propertyId,
                })
                if (res && res.data) {
                    this.financials = res.data
                }
            },

            resetModal() {
                this.financials = []
                this.deletedIds = []
            },

            addFinancial() {
                this.financials = [...this.financials, {
                    key: '',
                    key_id: '',
                    value: '',
                    priority: 0,
                    description: '',
                    description_id_locale: '',
                }]
            },

            removeFinancial(index) {
                const deletedItems = this.financials.splice(index, 1)
                deletedItems.forEach(e => {
                    if (e.id) {
                        this.deletedIds = [...this.deletedIds, e.id]
                    }
                });
            },

            async handleSubmit() {
                this.v$.$touch();
                const isFormCorrect = await this.v$.$validate()
                if (!isFormCorrect) {
                    window.scrollTo(0, 0)
                    return;
                }
                const res = await propertiesService.updateFinancials({
                    property_id: this.propertyId,
                    financials: this.financials,
                    deleted_ids: this.deletedIds,
                })
                if (res) {
                    this.$emit('on-close')
                }
            },
        },

        computed: {
            isDisabledEditing() {
                return !this.isEditing
            },

            canCreateOrUpdateFinancials() {
                return userHasPermission(USER_PERMISSIONS.PROPERTY_FINANCIAL.CREATE_OR_UPDATE_FINANCIALS)
            },
        },
    }
</script>

<style scoped>
    :deep() #full-screen-modal {
        padding: 0 !important;
        background: #f4f3ef;
    }

    :deep() #full-screen-modal .modal-dialog {
        width: 100%;
        max-width: 100%;
        height: 100vh;
        max-height: 100vh;
        min-height: 100vh;
        margin: 0;
        background: #f4f3ef;
    }

    :deep() #full-screen-modal .modal-content {
        height: 100%;
        border: none;
        border-radius: 0;
        background: #f4f3ef;
    }

    :deep() #full-screen-modal .modal-header {
        border-radius: 0;
    }

    :deep() .modal-header {
        position: fixed;
        top: 0;
        background-color: white;
        z-index: 100;
        width: 100%;
    }

    :deep() .modal-body {
        padding-top: 80px;
        padding-bottom: 80px;
        background-color: white;
    }

    :deep() .modal-footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: white;
        z-index: 100;
    }

    .financial-item {
        width: 80%;
        min-width: 700px;
        min-height: 50px;
        background-color: white;
        border-radius: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        border: 1px solid var(--primary-color);
        padding: 10px;
    }

    @media (max-width: 991px) {
        .financial-item {
            width: 100%;
            min-width: 100%;
        }
    }

    .input-invalid {
        border: 1px solid #dc3545;
        border-radius: 5px;
    }

    .input-valid {
        border: 1px solid #28a745;
        border-radius: 5px;
    }

    .input-normal {
        border: 1px solid #ced4da;
        border-radius: 5px;
    }
</style>
