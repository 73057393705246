<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">{{routeName}}</a>
      <button id="btn_showSidebar" class="navbar-toggler navbar-burger"
              type="button"
              @click="toggleSidebar"
              :aria-expanded="$sidebar.showSidebar"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
      </button>
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav ml-auto">
          <!-- <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="ti-panel"></i>
              <p>Stats</p>
            </a>
          </li> -->
          <!-- <drop-down class="nav-item"
                     title="5 Notifications"
                     title-classes="nav-link"
                     icon="ti-bell">
            <a class="dropdown-item" href="#">Notification 1</a>
            <a class="dropdown-item" href="#">Notification 2</a>
            <a class="dropdown-item" href="#">Notification 3</a>
            <a class="dropdown-item" href="#">Notification 4</a>
            <a class="dropdown-item" href="#">Another notification</a>
          </drop-down> -->
          <!-- <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="ti-settings"></i>
              <p>
                Settings
              </p>
            </a>
          </li> -->
        </ul>
        <user-avatar/>
      </div>
    </div>
  </nav>
</template>
<script>
import UserAvatar from "../../components/UserAvatar";
export default {
  components: {
    UserAvatar,
  },
  computed: {
    routeName() {
      const { path, name, query } = this.$route;

      const routeName = this.capitalizeFirstLetter(path.split('/').pop());

      if (name === 'adminProperties' && query && query.approval_status === 'PENDING') {
        return `${routeName} - Pending Approval`
      }

      return routeName
    },
  },
  data() {
    return {
      activeNotifications: false
    };
  },
  methods: {
    async logout() {
      await store.dispatch('doLogout');
      await this.$router.push({name: 'login'});
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    }
  }
};
</script>
<style type="scss">
  .user-avatar {
    button:hover{
      background-color: transparent;
    }

    .btn.dropdown-toggle {
      background-color: transparent !important;
    }
  }
</style>
