export const GTM_EVENT_NAMES = {
  ADD_BANK_ACCOUNT: "add_bank_account",
  APP_STORE_BUTTON: "app_store_button",
  BUY_PROPERTIES: "buy_properties",
  CHANGE_BANK_ACCOUNT: "change_bank_account",
  DOCUMENTS: "documents",
  ECA_INFORMATION: "eca_information",
  ERY_INFORMATION: "ery_information",
  FAQS: "faqs",
  FINANCIALS: "financials",
  GET_STARTED: "get_started",
  IRR_INFORMATION: "irr_information",
  MARKET: "market",
  NOTIFICATIONS: "notifications",
  PLAY_STORE_BUTTON: "play_store_button",
  REMEMBER_ME: "remember_me",
  SELL_PROPERTY: "sell_property",
  SELL_PROPERTY_SUCCESS: "sell_property_success",
  SWAP_PROPERTY: "swap_property",
  SWAP_PROPERTY_SUCCESS: "swap_property_success",
  SET_AMPLITUDE_USER_ID: "set_amplitude_user_id",
  TIMELINE: "timeline",
  BLOCKCHAIN: "blockchain",
  TOP_TOKEN_HOLDERS: "top_token_holders",
  WHAT_IS_GORO: "what_is_goro",
  USER_LOGIN: "user_login",
  USER_REQUEST_OTP: "user_request_otp",
  USER_REGISTER: "user_register",
  USER_REGISTER_SUCCESS: "user_register_success",
  USER_LOGOUT: "user_logout",
  USER_CLICKED_JOINNOW: "user_clicked_joinnow",
  USER_RESET_PASSWORD: "user_reset_password",
  USER_CLICKED_INVEST: "user_clicked_invest",
  USER_GET_REFERRAL_INVITER: "user_get_referral_inviter",
  USER_VIEW_PROPERTY: "user_view_property",
  USER_PAY: "user_pay",
  USER_PAY_SUCCESS: "user_pay_success",
  USER_REGISTRATION_COMPLETE: "user_registration_complete",

  // Revamp
  PAGE_VIEW: "Page View",
  COMPLETE_REGISTRATION: "Complete registration",
  VIEW_CONTENT: "View content",
  ADD_TO_CART: "Add to cart",
  INITIATE_CHECKOUT: "Initiate checkout",
  XENDIT_BEGIN_CHECKOUT: "Custom event: xendit_begin_checkout",
  XENDIT_PURCHASE: "Custom event: xendit_purchase",
  PURCHASE: "Purchase",
  COMPLETE_PAYMENT: "Complete Payment",
  LEAD: "Lead",
  CONTACT: "Contact",
};
