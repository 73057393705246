<template>
    <b-modal v-model="showModal" centered no-close-on-backdrop no-close-on-esc header-bg-variant="light" id="custom-modal-confirming-publish-property" modal-class="cls-custom-modal-confirming" @hidden="onHidden()">
        <template #modal-header>
            <h5 class="modal-header-title font-24 font-weight-bold mb-0">
                Publish Property
            </h5>
        </template>
        <div class="cls-content">
            <p class="font-18">
                <strong>WARNING!</strong> This action is irreversible. Are you sure?
            </p>
        </div>
        <template #modal-footer>
            <b-button variant="success" size="lg" class="pl-5 pr-5" @click="onConfirm({ show: false })">
                Yes
            </b-button>
            <b-button variant="primary" class="pl-5 pr-5" size="lg" @click="onClose({ show: false })">
                Cancel
            </b-button>
        </template>
    </b-modal>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    
    emits: ['on-close', 'on-confirm'],
    data() {
        return {
            showModal: false,
        }
    },
    watch: {
        show(value) {
            this.showModal = value
        }
    },
    methods: {
        onHidden() {
            this.showModal = false
        },
        onClose: function (v) {
            this.onHidden()
            this.$emit("onClose", v)
        },
        onConfirm: function (v) {
            this.onHidden()
            this.$emit("onConfirm", v)
        },
    },
}
</script>

<style lang="scss">
#custom-modal-confirming-publish-property___BV_modal_outer_{
    z-index: 10010 !important;
}
</style>
