<template>
  <div class="row">
    <div class="col-12">
      <div class="pb-2 d-flex cls-table-nav-filters flex-row align-items-center justify-content-between">
        <div class="mt-2 d-flex flex-row align-items-center justify-content-start">
          <b-button v-if="canCreateProperty" class="mr-3" size="sm" id="show-btn" @click="onToggleModal({ show: true })">
            <b-icon icon="plus-lg"></b-icon>
            Add new
          </b-button>
          <b-button v-if="false && hasFilterByApprovalStatus" class="pl-4 pr-4" size="sm" id="pending-listing-btn" @click="showAllProperties()">
            Show All Properties
          </b-button>
          <b-button v-if="false && canApprovePropertyPublish && !hasFilterByApprovalStatus" class="pl-4 pr-4" size="sm" id="pending-listing-btn" @click="propertyPendingListing()">
            Pending Approval
          </b-button>
        </div>
        <div class="mt-3 mt-md-2 d-flex flex-row align-items-center justify-content-start">
          <div class="d-flex flex-row align-items-center justify-content-start">
            <label class="mr-2 mb-0 cls-label" style="color:#212529;">Sort:</label><b-form-select v-model="orderBy" :options="sortOptions" size="sm"></b-form-select> 
          </div>
        </div>
      </div>
      <div slot="raw-content" class="table-responsive">
        <paper-table :data="properties.data" :columns="tableColumns" :show-edit-action="false" :show-delete-action="canDeleteProperty"
          :customActionTitles="customActions" @onModalEdit="onModalEdit"
          @onModalDelete="onModalDelete" @onCustomAction="onClickedCustomAction" :tr-class-field="'status'">
          <template #theadSearch>
            <thead class="search">
              <th width="30px" />
              <th width="5%">
                <b-input v-model="filters.uuid"></b-input>
              </th>
              <th>
                <b-input v-model="filters.name"></b-input>
              </th>
              <th width="10%">
                <b-select :options="statusList" v-model="filters.status"></b-select>
              </th>
              <th width="10%">
                <b-select :options="approvalStatusList" v-model="filters.approval_status"></b-select>
              </th>
              <th>
                <b-form-select v-model="filters.spc_id" text-field="name" value-field="id">
                  <template #first>
                    <b-form-select-option :value="null">Select spc</b-form-select-option>
                  </template>
                  <template v-for="spc in spcs">
                    <b-form-select-option :value="spc.id">
                      {{ spc.company_name }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </th>
              <th>
                <b-input v-model="filters.location"></b-input>
              </th>
              <th>
                <b-input v-model="filters.address"></b-input>
              </th>
              <th>
                <b-form-select v-model="filters.category_id" text-field="name" value-field="id">
                  <template #first>
                    <b-form-select-option :value="null">Select type</b-form-select-option>
                  </template>
                  <template v-for="category in categories">
                    <b-form-select-option :value="category.id">
                      {{ category.name }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th v-if="canDeleteProperty"></th>
            </thead>
          </template>
        </paper-table>
        <b-pagination class="mt-3" v-if="properties.total" align="right" v-model="properties.current_page"
          :total-rows="properties.total" :per-page="properties.per_page" @change="onChangePage"
          aria-controls="my-table"></b-pagination>
      </div>
    </div>

    <b-modal v-model="modalDelete.show" header-bg-variant="dark" header-text-variant="light">
      <template #modal-header>
        <h5 class="modal-header-title mb-0">Delete confirm</h5>
        <button id="btn_closeModalDeleteProperty" type="button" class="close font-weight-bold" aria-label="Close"
          @click="modalDelete.show = false">×</button>
      </template>
      <p v-if="modalDelete.content">Are you sure you wanna delete <strong style="color: red">{{ modalDelete.content
      }}</strong></p>
      <template #modal-footer>
        <b-button variant="danger" size="sm" @click="onDeleteProperty">
          Delete
        </b-button>
        <b-button variant="primary" size="sm" @click="modalDelete.show = false">
          Close
        </b-button>
      </template>
    </b-modal>
    <modal-create title="Create property" 
      :show="modalCreate"
      @onClose="onToggleModal">
    </modal-create>
    <modal-rental-distribution :show="modalRentalDistribution" :propertyId="rentalDistributionPropertyId"
      @on-close="onCloseRentalDistribution" />
    <modal-property-holders :show="modalPropertyHolders" :property="property" @on-close="modalPropertyHolders = false" />
    <modal-update-property-details title="Update property details" :show="modalUpdatePropertyDetails" :propertyId="updateDetailsPropertyId"
      @onClose="onCloseUpdatePropertyDetails"></modal-update-property-details>
    <modal-view-property-details title="View property details" :show="modalViewPropertyDetails" :propertyId="viewPropertyId"
      @onClose="onCloseViewPropertyDetails"></modal-view-property-details>
    <modal-copy-property title="Copy property" :show="modalCopyProperty" :propertyId="copyPropertyId"
      @onClose="onCloseCopyProperty"></modal-copy-property>
  </div>
</template>
<script>
import { PaperTable } from "../../components"
import ModalCreate from "./modals/ModalCreate.vue"
import ModalUpdatePropertyDetails from "./modals/ModalUpdatePropertyDetails.vue"
import ModalViewPropertyDetails from "./modals/ModalViewPropertyDetails.vue"
import ModalCopyProperty from "./modals/ModalCopyProperty.vue"
import ModalRentalDistribution from "@/pages/admin/rental-distribution/modals/ModalRentalDistribution.vue"
import ModalPropertyHolders from "@/pages/admin/modals/ModalPropertyHolders"
import propertiesService from "../../services/properties.service"
import spcsService from "../../services/spcs.service"
import categoryService from "../../services/categories.service"
import { userHasPermission } from "../../helpers/common"
import { USER_PERMISSIONS } from "../../constants/permissions"
import { PROPERTY_STATUSES_LIST, PROPERTY_CUSTOM_ACTIONS, PROPERTY_SHOW_MODE_STATUS, PROPERTY_APPROVAL_STATUS, PROPERTY_APPROVAL_STATUS_LIST } from "../../constants/constants"

const tableColumns = ["Id", "Uuid", "Name", "Status", "Approval Status", "Spc", "Location", "Address", "Type", "Sold_Tokens", "Weight"]

export default {
  components: {
    PaperTable,
    ModalCreate,
    ModalRentalDistribution,
    ModalPropertyHolders,
    ModalUpdatePropertyDetails,
    ModalViewPropertyDetails,
    ModalCopyProperty,
  },
  data() {
    return {
      modalCreate: false,
      modalUpdatePropertyStatus: false,
      modalUpdatePropertyDetails: false,
      modalViewPropertyDetails: false,
      modalCopyProperty: false,
      modalDelete: {
        show: false,
        content: '',
        id: null,
      },
      modalRentalDistribution: false,
      propertyId: null,
      property: null,
      properties: {},
      tableColumns: [...tableColumns],
      filters: {
        uuid: '',
        name: '',
        status: '',
        approval_status: '',
        spc_id: null,
        location: '',
        address: '',
        category_id: null,
      },
      statusList: PROPERTY_STATUSES_LIST,
      approvalStatusList: PROPERTY_APPROVAL_STATUS_LIST,
      spcs: [],
      categories: [],
      modalPropertyHolders: false,
      rentalDistributionPropertyId: null,
      updateDetailsPropertyId: null,
      viewPropertyId: null,
      copyPropertyId: null,
      sortOptions: [
        {
          value: {
            field: 'created_at',
            type: 'desc',
          },
          text: 'Newest'
        },
        {
          value: {
            field: 'created_at',
            type: 'asc',
          },
          text: 'Oldest'
        },
        {
          value: {
            field: 'status_weight',
            type: 'desc',
          },
          text: 'Most status weight'
        },
        {
          value: {
            field: 'status_weight',
            type: 'asc',
          },
          text: 'Least status weight'
        },
        {
          value: {
            field: 'weight',
            type: 'desc',
          },
          text: 'Most weight'
        },
        {
          value: {
            field: 'weight',
            type: 'asc',
          },
          text: 'Least weight'
        },
        {
          value: {
            field: 'updated_at',
            type: 'desc',
          },
          text: 'Recently updated'
        },
        {
          value: {
            field: 'updated_at',
            type: 'asc',
          },
          text: 'Least recently updated'
        }

      ],
      orderBy: {
        field: 'created_at',
        type: 'desc',
      },
      hasFilterByApprovalStatus: false,
    };
  },
  async mounted() {
    const approvalStatus = this.$route.query?.approval_status
    if (approvalStatus) {
      this.filters.approval_status = approvalStatus
      this.hasFilterByApprovalStatus = true
    }
    
    await Promise.all([
      this.getProperties(1),
      this.getSpcs(),
      this.getCategories(),
    ]);
  },
  watch: {
    async "filters.uuid"() {
      this.searchTimeOut(1)
    },
    async "filters.name"() {
      this.searchTimeOut(1)
    },
    async "filters.spc_id"() {
      this.searchTimeOut(1)
    },
    async "filters.location"() {
      this.searchTimeOut(1)
    },
    async "filters.address"() {
      this.searchTimeOut(1)
    },
    async "filters.status"() {
      this.getProperties(1)
    },
    async "filters.approval_status"() {
      this.getProperties(1)
    },
    async "filters.category_id"() {
      this.getProperties(1)
    },
    async "orderBy"() {
      await this.getProperties(1)
    },
    async '$route.query.approval_status'(newVal, oldVal) {
      if (newVal) {
        this.filters.approval_status = newVal
        this.hasFilterByApprovalStatus = true
      } else {
        this.filters.approval_status = null
        this.hasFilterByApprovalStatus = false
      }
      await this.getProperties(1)
    },
  },
  computed: {
    canApprovePropertyStatus() {
      return userHasPermission(USER_PERMISSIONS.PROPERTY.APPROVE_PROPERTY_STATUS_AND_TOKEN)
    },
    canApprovePropertyPublish() {
      return userHasPermission(USER_PERMISSIONS.PROPERTY.APPROVE_PROPERTY_PUBLISH)
    },
    canCreateProperty() {
      return userHasPermission(USER_PERMISSIONS.PROPERTY.CREATE_PROPERTY)
    },
    canDeleteProperty() {
      return userHasPermission(USER_PERMISSIONS.PROPERTY.DELETE_PROPERTY)
    },
    customActions() {
      let actions = []
      // actions = ['Rental Distribution', 'Holders', 'View', 'Modfify']

      // 'Rental Distribution'
      if (userHasPermission(USER_PERMISSIONS.DISTRIBUTION.UPDATE_RENTAL_DISTRIBUTION)) {
        actions.push(PROPERTY_CUSTOM_ACTIONS.UPDATE_RENTAL_DISTRIBUTION)
      }

      // 'Holders'
      if (userHasPermission(USER_PERMISSIONS.PROPERTY.VIEW_PROPERTY_HOLDER)) {
        actions.push(PROPERTY_CUSTOM_ACTIONS.VIEW_PROPERTY_HOLDER)
      }

      // 'View'
      if (userHasPermission(USER_PERMISSIONS.PROPERTY.VIEW_PROPERTY)) {
        let viewAction = PROPERTY_CUSTOM_ACTIONS.VIEW_PROPERTY
        if (this.canApprovePropertyPublish) {
          viewAction = {
            ...viewAction,
            label: PROPERTY_CUSTOM_ACTIONS.APPROVE_PROPERTY_PUBLISH.label,
            showConditions: {
              key: "approval_status",
              op: "!==",
              value: "PENDING",
              then: PROPERTY_CUSTOM_ACTIONS.VIEW_PROPERTY
            }
          }
        }
        actions.push(viewAction)
      }

      // 'Modify/Update'
      if (userHasPermission(USER_PERMISSIONS.PROPERTY.UPDATE_PROPERY_DETAIL)) {
        actions.push(PROPERTY_CUSTOM_ACTIONS.UPDATE_PROPERY_DETAIL)
      }

      // Copy
      if (userHasPermission(USER_PERMISSIONS.PROPERTY.COPY_PROPERTY)) {
        actions.push(PROPERTY_CUSTOM_ACTIONS.COPY_PROPERTY)
      }

      return actions
    },
  },
  methods: {
    async onDeleteProperty() {
      if (this.modalDelete.id) {
        await propertiesService.delete(this.modalDelete.id);
        this.modalDelete = {
          show: false,
          content: '',
          id: null,
        };
        await this.getProperties(1)
      }
    },
    onModalDelete(data) {
      this.modalDelete = data;
    },
    onModalEdit(data) {
      if (data) {
        this.updateDetailsPropertyId = data;
        this.modalUpdatePropertyDetails = true
      }
    },
    async onClickedCustomAction(data, index) {
      if (index === PROPERTY_CUSTOM_ACTIONS.UPDATE_RENTAL_DISTRIBUTION.key) {
        this.rentalDistributionPropertyId = data;
        this.modalRentalDistribution = true
      } else if (index === PROPERTY_CUSTOM_ACTIONS.VIEW_PROPERTY_HOLDER.key) {
        this.property = this.properties.data.find(e => e.id == data);
        this.modalPropertyHolders = true;
      }
      else if (index === PROPERTY_CUSTOM_ACTIONS.VIEW_PROPERTY.key) {
        this.viewPropertyId = data;
        this.modalViewPropertyDetails = true;
      }
      else if (index === PROPERTY_CUSTOM_ACTIONS.UPDATE_PROPERY_DETAIL.key) {
        this.updateDetailsPropertyId = data;
        this.modalUpdatePropertyDetails = true
      }
      else if (index === PROPERTY_CUSTOM_ACTIONS.COPY_PROPERTY.key) {
        this.copyPropertyId = data;
        this.modalCopyProperty = true
      }
    },
    onCloseRentalDistribution(created) {
      this.rentalDistributionPropertyId = null
      this.modalRentalDistribution = false
      if (created) {
        //do something
      }
    },
    async onToggleModal(data) {
      this.modalCreate = data.show;
      if (data.reload) {
        await this.getProperties(1)
      }
      if (data.show === false) {
        this.propertyId = null;
      }
      if (data.showEdit) {
        this.updateDetailsPropertyId = data.showEdit
        this.modalUpdatePropertyDetails = true
      }
    },

    async onCloseUpdatePropertyDetails(data) {
      this.modalUpdatePropertyDetails = false;
      if (data.reload) {
        await this.getProperties(1)
      }
      if (data.show === false) {
        this.updateDetailsPropertyId = null;
      }
    },

    async onCloseViewPropertyDetails(data) {
      this.modalViewPropertyDetails = false;
      if (data.reload) {
        await this.getProperties(1)
      }
      if (data.show === false) {
        this.viewPropertyId = null;
      }
    },

    async onCloseCopyProperty(data) {
      this.modalCopyProperty = false;
      if (data.reload) {
        await this.getProperties(1)
      }
      if (data.show === false) {
        this.copyPropertyId = null;
      }
      if (data.showEdit) {
        this.updateDetailsPropertyId = data.showEdit
        this.modalUpdatePropertyDetails = true
      }
    },

    async getSpcs() {
      const response = await spcsService.getSpcs()
      if (response && response.data) {
        this.spcs = response.data
      }
    },
    async getCategories() {
      this.categories = await categoryService.getCategories();
    },
    async getProperties(page) {
      const filters = {
        ...this.filters,
        order_by: this.orderBy.field,
        order_direction: this.orderBy.type,
        page,
        show: PROPERTY_SHOW_MODE_STATUS.ALL
      };
      const result = await propertiesService.getList(filters);
      if (result) {
        const { data } = result;
        data.map((property) => {
          property.type = property.category.name;
          property.bed = property.num_bed;
          property.bath = property.num_bath;
          property.spc = property.spc ? property.spc.company_name : ""
          property.location = property.location.name;
          property.address = property.metadata.address;
        });
        this.properties = result
      }
    },
    async onChangePage(page) {
      await this.getProperties(page)
    },
    searchTimeOut(page) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        await this.getProperties(page);
      }, 400);
    },
    async propertyPendingListing() {
      const currentQuery = { ...this.$route.query }
      currentQuery.approval_status = PROPERTY_APPROVAL_STATUS.PENDING
      this.$router.push({ query: currentQuery })
    },
    async showAllProperties() {
      await this.$router.push({ name: 'adminProperties' })
    },
  }
};
</script>
<style lang="scss" scoped>
.cls-table-nav-filters{
  @media only screen and (max-width: 768px) {
    flex-direction: column !important;
    align-items: flex-start !important;
    margin-bottom: 15px;
  }
}
</style>
